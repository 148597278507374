<template>
 <!-- eslint-disable -->
<svg class="logo-cooperatie crv-svg-icon"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 868.79 333.43">
    <path fill="#004380" d="M179.15,186.63c0-46.48,35.94-75,77.38-75,19.64,0,38.57,5.27,50.55,14.37v33.07c-11.5-9.83-26.84-16.77-44.08-16.77-24.19,0-42.64,15.56-42.64,44.32,0,28.5,18.45,44.31,42.64,44.31,17.24,0,32.58-6.95,44.08-16.77v33.06c-12,9.1-30.91,14.38-50.55,14.38-41.44,0-77.38-28.51-77.38-75" transform="translate(-64.8 -54.44)"/>
    <path fill="#990a2c" d="M385.64,178.48c14.85,0,21.56-6.71,21.56-18.93,0-12.46-6.71-19.16-21.56-19.16H368.87v38.09Zm73.79,80H417l-37.61-55.34H368.87v55.34H330.06V114.75h65.4c35,0,50.07,21.09,50.07,44.33,0,19.16-10.31,34.73-28,40.24Z" transform="translate(-64.8 -54.44)"/>
    <polygon fill="#004380" points="462.65 130.44 493.78 60.15 535.21 60.15 464.1 204.03 457.92 204.03 386.59 60.15 432.9 60.15 462.65 130.44"/><path fill="#004380" d="M749.12,77.51l20.49,27.81c10-7.66,16.53-20.68,16.53-35.45v-10s-20,10.11-38.39,15.89C748.2,76.38,748.67,76.92,749.12,77.51Z" transform="translate(-64.8 -54.44)"/>
    <path fill="#990a2c" d="M829.76,208.17c-6.82,40.77-40.22,49.34-60.51,50.15H569l196.3-50.42L712.49,93.71c-22.3-45.39-63.85-22.4-63.85-22.4,49.18-31.4,78.15-2.39,90.45,13.77l90.67,123.09" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M665.35,86.59c13.16,6.62,19.85,14.21,25.14,28.53H638.94c-20-2.73-33.25-25.91-30.12-48.8l1.32-9.66s49.65,27.13,55.21,29.93" transform="translate(-64.8 -54.44)"/>
    <polygon fill="#990a2c" points="448.81 289.89 470.21 311.28 448.81 332.68 462.13 332.68 483.52 311.28 462.13 289.89 448.81 289.89"/>
    <path fill="#004380" d="M479.67,387.87c11.52,0,17.09-5.89,17.09-13.7,0-15.95-24.52-11.4-24.52-18.19,0-2.56,2.17-4.29,6.08-4.29a18.28,18.28,0,0,1,12.42,4.68l5.06-6.66c-4.23-3.91-9.86-6-16.65-6-10,0-16.2,5.89-16.2,13,0,16.08,24.59,10.89,24.59,18.44,0,2.44-2.43,4.74-7.43,4.74a18.71,18.71,0,0,1-13.57-5.76l-4.93,6.91c4,4,9.86,6.79,18.06,6.79M448,387.1l12.17-42.71H450l-7.42,30.87-8.07-30.87h-7.11l-8.13,30.87-7.49-30.87H401.55l12.23,42.71h9.66l7.43-29.32,7.49,29.32Zm-69.79-7.3c-7.88,0-12.94-6.08-12.94-14s5.06-14,12.94-14,13,6,13,14-5.13,14-13,14m0,8.07c12.93,0,22.34-9.22,22.34-22.09s-9.41-22.09-22.34-22.09S356,352.91,356,365.78s9.41,22.09,22.29,22.09m-43.55,0c9.42,0,14.79-5.18,17.87-10.5l-7.81-3.78a11.47,11.47,0,0,1-10.06,6.21c-7.75,0-13.38-5.95-13.38-14s5.63-14,13.38-14A11.59,11.59,0,0,1,344.75,358l7.81-3.84a19.54,19.54,0,0,0-17.87-10.44c-12.8,0-22.73,9-22.73,22.09s9.93,22.09,22.73,22.09m-59.61-23.95h-9.54V352.4h9.54c3.65,0,6.4,2.17,6.4,5.76s-2.75,5.76-6.4,5.76M291,387.1l-9.6-16.26c4.61-1.09,9.35-5.12,9.35-12.68,0-7.94-5.44-13.77-14.34-13.77h-20V387.1h9.09V371.93h6.66l8.39,15.17Zm-41.68,0v-8H228.21v-9.67h20.68v-8H228.21v-9h21.13v-8H219.12V387.1Zm-48.47,0V352.4h12.42v-8H179.22v8h12.49v34.7Zm-37.27,0V352.4H176v-8H142v8h12.48v34.7Zm-26.12,0v-8H116.35v-9.67H137v-8H116.35v-9h21.13v-8H107.25V387.1Zm-51.87-7.87H73.89V369.3H85.61c3.59,0,5.51,2.31,5.51,5,0,3.07-2.05,4.93-5.51,4.93m-.32-17.74H73.89V352.2h11.4c3.07,0,5,2,5,4.68s-1.93,4.61-5,4.61M88,387.1c8.13,0,12.42-5.12,12.42-11.52,0-5.38-3.65-9.8-8.2-10.5,4-.84,7.37-4.42,7.37-9.8,0-5.7-4.16-10.89-12.3-10.89H64.8V387.1Z" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M933.59,387.1v-8H912.46v-9.67h20.68v-8H912.46v-9h21.13v-8H903.37V387.1Zm-57.69,0V369.43h20.68v-8H875.9v-9H897v-8H866.8V387.1Zm-17.55-42.71h-9.09V387.1h9.09ZM842.92,387.1v-8H824.86V344.39h-9.09V387.1Zm-66.08-23.18H767.3V352.4h9.54c3.65,0,6.4,2.17,6.4,5.76s-2.75,5.76-6.4,5.76m15.94,23.18-9.6-16.26c4.61-1.09,9.35-5.12,9.35-12.68,0-7.94-5.44-13.77-14.35-13.77h-20V387.1h9.09V371.93H774l8.39,15.17Zm-41.68,0v-8H730v-9.67h20.68v-8H730v-9H751.1v-8H720.88V387.1Zm-48.47,0V352.4h12.42v-8H681v8h12.49v34.7Zm-37.27,0V352.4h12.42v-8H643.72v8H656.2v34.7Zm-26.12,0v-8H618.11v-9.67h20.68v-8H618.11v-9h21.13v-8H609V387.1Zm-51.87-7.87H575.65V369.3h11.72c3.59,0,5.51,2.31,5.51,5,0,3.07-2,4.93-5.51,4.93m-.32-17.74h-11.4V352.2h11.4c3.07,0,5,2,5,4.68s-1.92,4.61-5,4.61m2.69,25.61c8.13,0,12.42-5.12,12.42-11.52,0-5.38-3.65-9.8-8.2-10.5,4-.84,7.37-4.42,7.37-9.8,0-5.7-4.16-10.89-12.3-10.89H566.56V387.1Z" transform="translate(-64.8 -54.44)"/>
    <rect fill="#004380" x="304.58" y="5.98" width="17.6" height="5.02"/><rect fill="#004380" x="310.82" width="5.11" height="17.25"/><path fill="#004380" d="M338.83,94.86a8.57,8.57,0,1,0-8.75-8.57,8.65,8.65,0,0,0,8.75,8.57" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M358.43,94.86a8.57,8.57,0,1,0-8.74-8.57,8.66,8.66,0,0,0,8.74,8.57" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M378,94.86a8.57,8.57,0,1,0-8.75-8.57A8.66,8.66,0,0,0,378,94.86" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M397.64,94.86a8.57,8.57,0,1,0-8.75-8.57,8.66,8.66,0,0,0,8.75,8.57" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M417.25,94.86a8.57,8.57,0,1,0-8.75-8.57,8.65,8.65,0,0,0,8.75,8.57" transform="translate(-64.8 -54.44)"/>
    <polygon fill="#004380" points="361.19 50.58 265.28 50.58 265.28 45.63 361.13 45.63 361.19 50.58"/>
    <path fill="#004380" d="M200.7,290.07c0-9.49,7-16,15.88-16a13.82,13.82,0,0,1,11.71,6.11l-3.24,1.8a10.43,10.43,0,0,0-8.47-4.49c-6.76,0-11.89,5.18-11.89,12.55s5.13,12.54,11.89,12.54a10.3,10.3,0,0,0,8.47-4.49l3.28,1.8A14,14,0,0,1,216.58,306C207.69,306,200.7,299.55,200.7,290.07Z" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M231.79,294.28c0-6.48,4.25-11.71,11.06-11.71s11.06,5.23,11.06,11.71S249.65,306,242.85,306,231.79,300.76,231.79,294.28Zm18.46,0c0-4.54-2.64-8.61-7.4-8.61s-7.45,4.07-7.45,8.61,2.68,8.65,7.45,8.65S250.25,298.86,250.25,294.28Z" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M258.24,294.28c0-6.48,4.26-11.71,11.06-11.71s11.06,5.23,11.06,11.71S276.1,306,269.3,306,258.24,300.76,258.24,294.28Zm18.47,0c0-4.54-2.64-8.61-7.41-8.61s-7.45,4.07-7.45,8.61,2.68,8.65,7.45,8.65S276.71,298.86,276.71,294.28ZM264.53,279.7a2.16,2.16,0,1,1,2.13-2.18A2.17,2.17,0,0,1,264.53,279.7Zm9.54,0a2.16,2.16,0,1,1,2.17-2.18A2.2,2.2,0,0,1,274.07,279.7Z" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M289.46,302.1V314H286V283.12h3.47v3.33a9.37,9.37,0,0,1,7.55-3.88c5.87,0,9.95,4.44,9.95,11.71S302.88,306,297,306A9.16,9.16,0,0,1,289.46,302.1Zm13.84-7.82c0-5-2.69-8.61-7.18-8.61a8.27,8.27,0,0,0-6.66,3.56v10.09a8.32,8.32,0,0,0,6.66,3.61C300.61,302.93,303.3,299.23,303.3,294.28Z" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M311.15,294.28c0-6.48,4.63-11.71,11-11.71,6.76,0,10.74,5.27,10.74,12v.88H314.81c.27,4.22,3.23,7.73,8.05,7.73a9.73,9.73,0,0,0,6.89-2.82l1.67,2.27a12.15,12.15,0,0,1-8.89,3.42C315.92,306,311.15,301.26,311.15,294.28Zm11-8.84c-4.77,0-7.13,4-7.31,7.45h14.67C329.43,289.56,327.21,285.44,322.12,285.44Z" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M338.48,305.48V283.12H342v3.62a9.52,9.52,0,0,1,7.45-4.08v3.57a5.6,5.6,0,0,0-1.38-.15,8.18,8.18,0,0,0-6.07,3.57v15.83Z" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M367.72,305.48v-2.55a9.64,9.64,0,0,1-7.4,3.1c-3.8,0-7.82-2.54-7.82-7.4s4-7.36,7.82-7.36a9.34,9.34,0,0,1,7.4,3.05v-4c0-3-2.4-4.73-5.64-4.73a8.84,8.84,0,0,0-6.85,3.11l-1.62-2.41a11.68,11.68,0,0,1,8.93-3.7c4.72,0,8.65,2.13,8.65,7.54v15.37Zm0-4.72v-4.22a7.47,7.47,0,0,0-6.15-2.77c-3.24,0-5.51,2-5.51,4.91s2.27,4.85,5.51,4.85A7.47,7.47,0,0,0,367.72,300.76Z" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M378.81,300.8V286.18h-3.7v-3.06h3.7V277h3.47v6.11h4.54v3.06h-4.54v13.88c0,1.67.74,2.87,2.27,2.87a3.34,3.34,0,0,0,2.36-.92l1,2.59a5.69,5.69,0,0,1-4.17,1.43C380.48,306,378.81,304.13,378.81,300.8Z" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M391.12,277.62a2.34,2.34,0,1,1,4.67,0,2.34,2.34,0,0,1-4.67,0Zm.6,27.86V283.12h3.47v22.36Z" transform="translate(-64.8 -54.44)"/>
    <path fill="#004380" d="M400.82,294.28c0-6.48,4.63-11.71,11-11.71,6.76,0,10.74,5.27,10.74,12v.88h-18.1c.28,4.22,3.24,7.73,8,7.73a9.75,9.75,0,0,0,6.9-2.82l1.66,2.27a12.14,12.14,0,0,1-8.88,3.42C405.59,306,400.82,301.26,400.82,294.28Zm11-8.84c-4.77,0-7.12,4-7.31,7.45h14.67C419.11,289.56,416.88,285.44,411.79,285.44Z" transform="translate(-64.8 -54.44)"/>
</svg><!-- eslint-enable -->
</template>