<template>
    <v-app-bar-title 
        :class="classes"
    >
        <a
            class="pl-0"
            @click="handleClick"
        >
            <slot />
        </a>
    </v-app-bar-title>
</template>


<script>
export default {
    name: 'CrvAppTitle',

    props: {
        allowClick: {
            type: Boolean,
            default: false,
        },
    },

    emits: [
        'click'
    ],

    computed: {
        classes() {
            return {
                'is-clickable': this.allowClick,
            }
        },    
    },

    methods: {
        handleClick() {
            if (this.allowClick) {
                this.$emit('click')
            }
        },    
    },
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';
    .is-clickable {
        cursor: pointer;
    }

</style>