<template>
 <!-- eslint-disable -->
<svg class="collar crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 385">
    <g fill="none" fill-rule="evenodd">
        <rect width="512" height="385" fill="#FFF"/>
        <g transform="translate(0 186)">
            <ellipse cx="256" cy="149" fill="#F7F6F6" rx="256" ry="50"/>
            <g transform="translate(55)">
                <path fill="#928782"
                      d="M202,0 C313.561519,0 404,27.5344879 404,61.5 C404,61.6669888 403.997814,61.8338221 403.99345,62.0004974 L404,62 L404,102 L403.94113,102.000466 C403.980302,102.498865 404,102.998732 404,103.5 C404,137.465512 313.561519,165 202,165 C90.4384805,165 0,137.465512 0,103.5 C0,102.998732 0.0196977559,102.498865 0.0588704626,102.000466 L0,102 L0,62 L0.013,62 L0,61.5 C0,27.874143 88.6387548,0.55133286 198.659561,0.0082392429 Z M202,3 C92.6476195,3 4,29.4151998 4,62 C4,94.5848002 92.6476195,121 202,121 C311.35238,121 400,94.5848002 400,62 C400,29.4151998 311.35238,3 202,3 Z"
                      transform="matrix(1 0 0 -1 0 165)"/>
                <path fill="#BCB4B1"
                      d="M202,0 C313.561519,0 404,27.5344879 404,61.5 C404,61.6669888 403.997814,61.8338221 403.99345,62.0004974 L404,62 L404,102 L403.94113,102.000466 C403.980302,102.498865 404,102.998732 404,103.5 C404,137.465512 313.561519,165 202,165 C90.4384805,165 0,137.465512 0,103.5 C0,102.998732 0.0196977559,102.498865 0.0588704626,102.000466 L0,102 L0,62 L0.013,62 L0,61.5 C0,27.874143 88.6387548,0.55133286 198.659561,0.0082392429 Z M202,3 C92.6476195,3 4,29.4151998 4,62 C4,94.5848002 92.6476195,121 202,121 C311.35238,121 400,94.5848002 400,62 C400,29.4151998 311.35238,3 202,3 Z"/>
                <g transform="rotate(-87 138.546 35.206)">
                    <rect width="66" height="66" fill="#FEDB55" rx="4"/>
                    <text fill="#262322" font-family="OpenSans-SemiBold, Open Sans" font-size="55" font-weight="500"
                          transform="rotate(90 33 33)">
                        <tspan x="17" y="59">4</tspan>
                    </text>
                </g>
                <g transform="rotate(-90 172.5 3.5)">
                    <rect width="66" height="66" fill="#FEDB55" rx="4"/>
                    <text fill="#262322" font-family="OpenSans-SemiBold, Open Sans" font-size="55" font-weight="500"
                          transform="rotate(90 33 33)">
                        <tspan x="17" y="59">0</tspan>
                    </text>
                </g>
                <g transform="rotate(-94 203.632 -26.196)">
                    <rect width="66" height="66" fill="#FEDB55" rx="4"/>
                    <text fill="#262322" font-family="OpenSans-SemiBold, Open Sans" font-size="55" font-weight="500"
                          transform="rotate(90 33 33)">
                        <tspan x="17" y="59">4</tspan>
                    </text>
                </g>
            </g>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>