<script>
import { logoWithTagline, phoneAltSolid, sloganCrv } from '@/images/icons'


export default {
    name: 'CrvPdfPage',
    components: { logoWithTagline, phoneAltSolid, sloganCrv },

    props: {
        isFirstPage: {
            type: Boolean,
            default: false,
            note: 'Shows the default main header on top of the page.'
        },

        title: {
            type: String,
            default: '',
            note: 'Required title of the document',
        },

        subtitle: {
            type: String,
            default: '',
            note: 'Optional subtitle of the document',
        },

        identifier: {
            type: String,
            default: '',
            note: 'Display information about the customer'
        },

        footerText: {
            type: String,
            default: 'CRV B.V. Postbus 454 6800 AL Arnhem',
            note: 'Text displayed inside the footer'
        },

        phoneNumber: {
            type: String,
            default: '088 0024440',
            note: 'Phonenumber of customer-support set in the footer, when set to ""(empty) the phone-icon is hidden also '
        },

        dateLabel: {
            type: String,
            default: '',
            note: 'Overwrite of the displayed date inside the footer'
        }
    },

    methods: {
        printDate() {
            return new Date().toLocaleString()
        }
    },
}
</script>

<template>
    <div class="pdf-page">
        <div
            v-if="isFirstPage"
            class="pdf-header"
        >
            <slot name="logo">
                <logo-with-tagline class="default-logo" />
            </slot>
            <div class="pdf-header__text">
                <h2 class="pdf-title">
                    {{ title }}
                </h2>
                <h3 class="pdf-subtitle">
                    {{ subtitle }}
                </h3>
                <p class="pdf-customer">
                    {{ identifier }}
                </p>
            </div>
        </div>
        <slot />
        <div class="pdf-footer">
            <p>{{ footerText }}</p>
            <span
                v-if="phoneNumber"
                class="phone-number"
            >
                <phone-alt-solid class="phone-icon" />
                {{ phoneNumber }}
            </span>
            <span>
                <slogan-crv class="slogan-crv" />
            </span>
            <p>{{ dateLabel || printDate() }}</p>
            <p class="page-numbers-placeholder">
&numsp;
            </p>
        </div>
    </div>
</template>


<style lang="scss" scoped>
@import '@/assets/variables.scss';
$vertical-margins: $vertical-spacing-half;
$horizontal-margins: $horizontal-spacing-double;

.pdf-page {
    height: 1086px;
    width: 100%;
    position: relative;
    padding-left: 18px;
    // margin for perforated printed document

    .pdf-header {
        height: 80px;
        display: flex;
        width: 100%;

        min-height: unset;
        margin-bottom: $vertical-margins;

        .default-logo {
            width: 200px;
            height: 80px;
        }

        &__text {
            margin: 0 $horizontal-spacing-double;
        }

        &__text > * {
            margin-bottom: 8px;
        }

        > * {
            margin-right: $vertical-spacing-double;
        }

        .pdf-title {
            // Explicitly set for offline pdf-printing (otherwise styling is missing)
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 8px;
            line-height: 21px;
        }

        .pdf-subtitle {
            // Explicitly set for offline pdf-printing (otherwise styling is missing)
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 8px;
            line-height: 21px;
        }

        .pdf-customer {
            // Explicitly set for offline pdf-printing (otherwise styling is missing)
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 8px;
            line-height: 21px;
        }
    }

    .pdf-footer {
        position: absolute;
        bottom: 5px;
        margin-bottom: 1px;
        display: flex;
        width: 100%;
        justify-content: space-between;

        .slogan-crv {
            width: 145px; 
            height: 8px;
        }

        .phone-icon {
            width: 15px; 
            height: 15px; 
            margin: 2px 5px 0 0;
        }

        .phone-number {
            display: flex;
        }

        .page-numbers-placeholder {
            width: 75px;
        }

        > * {
            margin: $vertical-margins $horizontal-spacing-single;
            font-size: 12px;
        }
    }
}
</style>