<template>
 <!-- eslint-disable -->
<svg class="under-construction crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 385">
    <defs>
        <rect id="under-construction-a" width="288.8" height="4.475"/>
        <rect id="under-construction-c" width="288.8" height="47.475"/>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(0 153)">
        <ellipse cx="256" cy="182" fill="#F7F6F6" rx="256" ry="50"/>
        <g transform="translate(66)">
            <g transform="translate(45.6 45.475)">
                <mask id="under-construction-b" fill="#fff">
                    <use xlink:href="#under-construction-a"/>
                </mask>
                <use fill="#FEDB55" xlink:href="#under-construction-a"/>
                <rect width="291" height="4" x="-.6" y="-.475" fill="#DDBE47" mask="url(#under-construction-b)"/>
                <path fill="#7F746F"
                      d="M57.3827887,-40.4201987 L90.8779506,-6.94284391 L-10.0134766,93.8949461 L-43.5086384,60.4175914 L57.3827887,-40.4201987 Z M191.850514,-40.4201987 L225.345675,-6.94284391 L124.454248,93.8949461 L90.9590864,60.4175914 L191.850514,-40.4201987 Z M324.790196,-40.4201987 L358.285358,-6.94284391 L257.393931,93.8949461 L223.898769,60.4175914 L324.790196,-40.4201987 Z"
                      mask="url(#under-construction-b)"/>
            </g>
            <g transform="translate(45.6 48.475)">
                <mask id="under-construction-d" fill="#fff">
                    <use xlink:href="#under-construction-c"/>
                </mask>
                <use fill="#FEDB55" xlink:href="#under-construction-c"/>
                <path fill="#928782"
                      d="M57.3827887,-43.4201987 L90.8779506,-9.94284391 L-10.0134766,90.8949461 L-43.5086384,57.4175914 L57.3827887,-43.4201987 Z M191.850514,-43.4201987 L225.345675,-9.94284391 L124.454248,90.8949461 L90.9590864,57.4175914 L191.850514,-43.4201987 Z M324.790196,-43.4201987 L358.285358,-9.94284391 L257.393931,90.8949461 L223.898769,57.4175914 L324.790196,-43.4201987 Z"
                      mask="url(#under-construction-d)"/>
            </g>
            <path fill="#928782"
                  d="M40.85,189 C18.289168,189 3.69482223e-13,183.89876 3.69482223e-13,177.606061 C3.69482223e-13,171.313362 18.289168,166.212121 40.85,166.212121 C63.410832,166.212121 81.7,171.313362 81.7,177.606061 C81.7,183.89876 63.410832,189 40.85,189 Z M339.15,189 C316.589168,189 298.3,183.89876 298.3,177.606061 C298.3,171.313362 316.589168,166.212121 339.15,166.212121 C361.710832,166.212121 380,171.313362 380,177.606061 C380,183.89876 361.710832,189 339.15,189 Z"/>
            <path fill="#BCB4B1"
                  d="M334.4,40.8787879 L345.8,40.8787879 L345.8,177.606061 C344.6,178.535354 342.7,179 340.1,179 C337.5,179 335.6,178.535354 334.4,177.606061 L334.4,40.8787879 Z M34.2,40.8787879 L45.6,40.8787879 L45.6,177.606061 C44.5333333,178.535354 42.6333333,179 39.9,179 C37.1666667,179 35.2666667,178.535354 34.2,177.606061 L34.2,40.8787879 Z"/>
            <path fill="#D2CCC8"
                  d="M340.1,43 C336.951977,43 334.4,42.1045695 334.4,41 C334.4,39.8954305 336.951977,39 340.1,39 C343.248023,39 345.8,39.8954305 345.8,41 C345.8,42.1045695 343.248023,43 340.1,43 Z M39.9,43 C36.7519769,43 34.2,42.1045695 34.2,41 C34.2,39.8954305 36.7519769,39 39.9,39 C43.0480231,39 45.6,39.8954305 45.6,41 C45.6,42.1045695 43.0480231,43 39.9,43 Z"/>
            <path fill="#D68107"
                  d="M73.35,47.4747475 C60.2332372,47.4747475 49.6,36.8471633 49.6,23.7373737 C49.6,10.6275842 60.2332372,-6.03961325e-14 73.35,-6.03961325e-14 C86.4667628,-6.03961325e-14 97.1,10.6275842 97.1,23.7373737 C97.1,36.8471633 86.4667628,47.4747475 73.35,47.4747475 Z M314.65,47.4747475 C301.533237,47.4747475 290.9,36.8471633 290.9,23.7373737 C290.9,10.6275842 301.533237,-6.03961325e-14 314.65,-6.03961325e-14 C327.766763,-6.03961325e-14 338.4,10.6275842 338.4,23.7373737 C338.4,36.8471633 327.766763,47.4747475 314.65,47.4747475 Z M194.95,47.4747475 C181.833237,47.4747475 171.2,36.8471633 171.2,23.7373737 C171.2,10.6275842 181.833237,-6.03961325e-14 194.95,-6.03961325e-14 C208.066763,-6.03961325e-14 218.7,10.6275842 218.7,23.7373737 C218.7,36.8471633 208.066763,47.4747475 194.95,47.4747475 Z"/>
            <path fill="#FF9600"
                  d="M69.35,48.4747475 C56.2332372,48.4747475 45.6,37.8471633 45.6,24.7373737 C45.6,11.6275842 56.2332372,1 69.35,1 C82.4667628,1 93.1,11.6275842 93.1,24.7373737 C93.1,37.8471633 82.4667628,48.4747475 69.35,48.4747475 Z M310.65,48.4747475 C297.533237,48.4747475 286.9,37.8471633 286.9,24.7373737 C286.9,11.6275842 297.533237,1 310.65,1 C323.766763,1 334.4,11.6275842 334.4,24.7373737 C334.4,37.8471633 323.766763,48.4747475 310.65,48.4747475 Z M190.95,48.4747475 C177.833237,48.4747475 167.2,37.8471633 167.2,24.7373737 C167.2,11.6275842 177.833237,1 190.95,1 C204.066763,1 214.7,11.6275842 214.7,24.7373737 C214.7,37.8471633 204.066763,48.4747475 190.95,48.4747475 Z"/>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>