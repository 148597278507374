<template>
 <!-- eslint-disable -->
<svg class="no-tasks crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 385">
    <g fill="none" fill-rule="evenodd" transform="translate(0 36)">
        <ellipse cx="256" cy="299" fill="#F7F6F6" rx="256" ry="50"/>
        <g transform="rotate(-14 195.36 -422.604)">
            <path fill="#4074A4"
                  d="M83.6313966,18.8917868 C84.7380247,8.89189692 93.2251406,1.11400905 103.530846,1.11400905 C113.836551,1.11400905 122.323666,8.89189692 123.430295,18.8917868 L137.827123,18.8917868 C147.6357,18.8917868 155.587123,26.8432093 155.587123,36.651786 L155.587123,44.6873432 C155.587123,54.4959199 147.6357,62.4473424 137.827123,62.4473424 L70.1244186,62.4473424 C60.3158419,62.4473424 52.3644194,54.4959199 52.3644194,44.6873432 L52.3644194,36.651786 C52.3644194,26.8432093 60.3158419,18.8917868 70.1244186,18.8917868 L83.6313966,18.8917868 Z"/>
            <rect width="205.556" height="255.778" x=".135" y="33.284" fill="#E4E0DE" rx="17.76"/>
            <rect width="205.556" height="252.444" x="4.444" y="33.111" fill="#EAE8E5" rx="17.76"/>
            <rect width="174.411" height="212.444" x="19.572" y="51.778" fill="#D2CCC8"/>
            <path fill="#FFF"
                  d="M19.5719096,51.7777778 L193.982684,51.7777778 L193.982684,207.333333 C193.982684,215.62963 194.872535,226 196.652237,238.444444 C198.431938,250.888889 200.798884,260.962963 203.753073,268.666667 L30.2321496,268.666667 C26.0914926,259.185185 23.4279301,250.592593 22.2414622,242.888889 C20.4617605,231.333333 19.5719096,221.555556 19.5719096,207.333333 C19.5719096,197.851852 19.5719096,146 19.5719096,51.7777778 Z"/>
            <path stroke="#D2CCC8" stroke-dasharray="13.32" stroke-linecap="round" stroke-width="5.328"
                  d="M74.2977393 106.444444L175.740741 106.444444M74.2977393 155.333333L175.740741 155.333333M74.2977393 201.555556L175.740741 201.555556M85.8658009 248.222222L187.308802 248.222222"/>
            <path fill="#5789BB"
                  d="M87.1345178,18.6200868 C88.2411459,8.62019687 96.7282618,0.842309005 107.033967,0.842309005 C117.339672,0.842309005 125.826788,8.62019687 126.933416,18.6200868 L141.330245,18.6200868 C151.138821,18.6200868 159.090244,26.5715093 159.090244,36.380086 L159.090244,44.4156432 C159.090244,54.2242199 151.138821,62.1756423 141.330245,62.1756423 L73.6275398,62.1756423 C63.8189631,62.1756423 55.8675406,54.2242199 55.8675406,44.4156432 L55.8675406,36.380086 C55.8675406,26.5715093 63.8189631,18.6200868 73.6275398,18.6200868 L87.1345178,18.6200868 Z"/>
            <rect width="27.593" height="29.337" x="34.696" y="79.332" stroke="#99BDDE" stroke-width="3.552"/>
            <rect width="27.593" height="29.337" x="34.696" y="127.332" stroke="#99BDDE" stroke-width="3.552"/>
            <rect width="27.593" height="29.337" x="34.696" y="175.332" stroke="#99BDDE" stroke-width="3.552"/>
            <path stroke="#99BDDE" stroke-width="3.552"
                  d="M34.7851344,223.331555 C35.1657469,230.609134 35.7187389,235.826032 36.4306302,238.93719 C37.1463357,242.065016 38.7120047,246.057864 41.1300519,250.890667 L68.6172861,250.890667 C66.4651085,245.095633 65.0320422,240.642341 64.3146756,237.507255 C63.5648206,234.230186 62.9373558,229.512448 62.4244476,223.331555 L34.7851344,223.331555 Z"/>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>