import colors from '../base-colors.module.scss'

const crvTheme = {
    dark: false,
    colors: {
        // Make sure the actual colors are being exported inside the _base-colors.scss
        background: colors.BASE_WHITE,
        surface: colors.BASE_WHITE,
        primary: colors.BASE_BLUE_80,
        'on-primary': colors.BASE_WHITE,
        'primary-darken-1': colors.BASE_BLUE_100,
        secondary: colors.BASE_BLUE_10, // background-color / color="secondary"
        'on-secondary': colors.BASE_BLUE_80, // text-color / auto-class based on color="secondary"
        submit: colors.BASE_BLUE_90,
        danger: colors.NOTIFICATION_DANGER,
        'on-danger': colors.BASE_WHITE,
        error: colors.NOTIFICATION_DANGER,
        info: colors.NOTIFICATION_INFO,
        success: colors.NOTIFICATION_SUCCES,
        warning: colors.NOTIFICATION_WARNING,
        'base-white': colors.BASE_WHITE,
        'base-gray-100': colors.BASE_GRAY_100,
        'base-gray-90': colors.BASE_GRAY_90,
        'base-gray-80': colors.BASE_GRAY_80,
        'base-gray-70': colors.BASE_GRAY_70,
        'base-gray-60': colors.BASE_GRAY_60,
        'base-gray-50': colors.BASE_GRAY_50,
        'base-gray-40': colors.BASE_GRAY_40,
        'base-gray-30': colors.BASE_GRAY_30,
        'base-gray-20': colors.BASE_GRAY_20,
        'base-gray-10': colors.BASE_GRAY_10,
        'base-gray-5': colors.BASE_GRAY_5,
        'base-blue-100': colors.BASE_BLUE_100,
        'base-blue-90': colors.BASE_BLUE_90,
        'base-blue-80': colors.BASE_BLUE_80,
        'base-blue-70': colors.BASE_BLUE_70,
        'base-blue-60': colors.BASE_BLUE_60,
        'base-blue-50': colors.BASE_BLUE_50,
        'base-blue-40': colors.BASE_BLUE_40,
        'base-blue-30': colors.BASE_BLUE_30,
        'base-blue-20': colors.BASE_BLUE_20,
        'base-blue-10': colors.BASE_BLUE_10,
        'base-blue-5': colors.BASE_BLUE_5,
        'base-brand-blue': colors.BASE_BRAND_BLUE,
        'base-brand-red': colors.BASE_BRAND_RED,
        'notification-danger': colors.NOTIFICATION_DANGER,
    }
}

export default crvTheme
