<template>
    <v-container 
        class="crv-app-page" 
        :class="containerClasses"
        fluid
    >
        <v-row 
            v-if="showHeader"
            no-gutters
        >
            <v-col 
                :class="headerClasses"
            >
                <v-icon 
                    v-if="shouldShowNavBack"
                    size="x-large"
                    color="base-gray-90"
                    class="ml-n4"
                    :icon="mdiChevronLeft"
                    @click="handleClickBack"
                />
                <h1>
                    {{ title }}
                </h1>
                <slot name="after-title" />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col :class="bodyClasses">
                <slot />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'


export default {
    name: 'CrvAppPage',

    props: {
        title: {
            type: String,
            default: undefined,
        },

        shouldShowNavBack: {
            type: Boolean,
            default: true,
        },

        containerClasses: {
            type: String,
            default: 'pa-0 pa-sm-12',
            note: ` default padding of the container is 48px for smAndUp (tablet+) 
                    for xs (mobile) the padding is disabled because tables should have no padding/margins`,
        },

        headerClasses: {
            type: String,
            default: 'd-flex align-center pb-sm-12',
            note: 'header classess only applied on tablet+ and when title is passed in',
        },

        bodyClasses: {
            type: String,
            default: '',
        },
    },

    static() {
        return {
            mdiChevronLeft,            
        }
    },

    computed: {
        showHeader() {
            return this.$vuetify.display.smAndUp && !!this.title
        },
    },

    methods: {
        handleClickBack() {
            this.$emit('click-back')
        },
    },
}
</script>


<style lang='scss' scoped>
@import '@/assets/variables.scss';

.crv-app-page {
    min-height: calc(100vh - #{$desktop-header-height});
}

</style>
