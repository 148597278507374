<template>
 <!-- eslint-disable -->
<svg class="milkcan-down crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 385">
    <defs>
        <path id="milkcan-down-b"
              d="M283,173.498081 C286.138994,179.342949 287.805661,183.843589 288,187 C288.291509,191.734617 275.49505,184.532823 265.850904,191 C256.206759,197.467177 263.078662,207.7846 229.039331,207 C195,206.2154 220.409222,206.2154 192.929905,207 C165.450587,207.7846 157.620062,199.423436 138.929905,212 C120.239747,224.576564 97.6122222,233.324121 136.290096,245.112797 C174.967971,256.901474 247.276034,254.810928 276.729149,254 C306.182263,253.189072 299.445889,243.154336 321.988302,240 C344.530716,236.845664 354.385413,241.887679 376.779916,240 C399.174419,238.112321 400.751608,235.64204 416.896398,232 C433.041187,228.35796 442.755228,238.270622 447.380714,224.073441 C452.0062,209.876261 441.191816,199.672933 429.60272,193 C418.013623,186.327067 397.385899,181.577461 379.520616,180 C367.610427,178.948359 355.103555,180.281693 342,184 C327.847456,188.078665 317.377416,188.078665 310.589881,184 C300.408579,177.882003 306.857161,175.80973 304.351827,170 C302.681605,166.126847 299.813792,163.126847 295.748388,161 C292.582796,160.333333 288.666667,160 284,160 C279.333333,160 276,160.333333 274,161 C276.057681,165.895199 277.724347,169.228532 279,171 C280.275653,172.771468 281.608986,173.604162 283,173.498081 Z"/>
        <filter id="milkcan-down-a" width="110.8%" height="138%" x="-5.4%" y="-19%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius="2" result="shadowSpreadOuter1"/>
            <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0638586957 0"/>
        </filter>
        <radialGradient id="milkcan-down-c" fx="50%" fy="50%" gradientTransform="matrix(0 -1 .88022 0 .06 1)">
            <stop offset="0%" stop-color="#D9D5D3"/>
            <stop offset="100%" stop-color="#BAB0AC"/>
        </radialGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(0 116)">
        <ellipse cx="256" cy="219" fill="#F7F6F6" rx="256" ry="50"/>
        <g transform="translate(13)">
            <ellipse cx="124.601" cy="207.835" fill="#F1F0EF" rx="113.5" ry="15" transform="rotate(5 124.6 207.835)"/>
            <path fill="#EAE8E5"
                  d="M36,29.3136721 L230,15.5534443 L230,205.196075 L36,190.086033 C16.117749,188.537468 1.42108547e-14,152.491277 1.42108547e-14,109.574612 C1.42108547e-14,66.6579472 16.117749,30.7239004 36,29.3136721 Z"/>
            <path fill="#E4E0DE"
                  d="M230,205.196075 C249.882251,206.74464 266,164.347763 266,110.5 C266,56.6522369 249.882251,14.143216 230,15.5534443 C210.117749,16.9636727 194,59.3605495 194,110.249519 C194,161.138489 210.117749,203.64751 230,205.196075 Z"/>
            <path fill="#EAE8E5"
                  d="M257,43 L285,43 C295.49341,43 304,73.2207794 304,110.5 C304,147.779221 295.49341,178 285,178 L257,178 C246.50659,178 238,147.779221 238,110.5 C238,73.2207794 246.50659,43 257,43 Z"/>
            <ellipse cx="285" cy="110.5" fill="#A29894" rx="18" ry="63.5"/>
            <use fill="#000" filter="url(#milkcan-down-a)" xlink:href="#milkcan-down-b"/>
            <use fill="#FFF" xlink:href="#milkcan-down-b"/>
            <g transform="translate(376 160)">
                <path fill="#EAE8E5"
                      d="M0.0200568791,43 L0,43 L0,24 L0.0200568791,24 C0.822119329,14.0138751 25.5781547,6 56,6 C86.4218453,6 111.177881,14.0138751 111.979943,24 L112,24 L112,43 L111.979943,43 C111.177881,52.9861249 86.4218453,61 56,61 C25.5781547,61 0.822119329,52.9861249 0.0200568791,43 Z"/>
                <ellipse cx="56" cy="24.5" fill="#E4E0DE" rx="56" ry="18.5"/>
                <ellipse cx="56" cy="24.5" fill="url(#milkcan-down-c)" rx="43" ry="14.5"/>
                <polygon fill="#E4E0DE" points="46.783 6.313 58.555 -8.836 60.993 42.213 45.992 56.784"
                         transform="rotate(58 53.493 23.974)"/>
            </g>
            <path fill="#D2CCC8"
                  d="M212.745878,29 C216.248626,14.6810794 222.333333,7.01441271 231,6 C239.666667,4.98558729 244.43101,10.9855873 245.29303,24 L248.929905,29 C249.587978,7.66666667 243.247408,-1.50720635 229.908195,1.47838094 C216.568982,4.46396823 208.916919,16.6378413 206.952008,38 L212.745878,29 Z"/>
            <path fill="#D2CCC8"
                  d="M212.745878,210 C216.248626,195.681079 222.333333,188.014413 231,187 C239.666667,185.985587 244.43101,191.985587 245.29303,205 L248.929905,210 C249.587978,188.666667 243.247408,179.492794 229.908195,182.478381 C216.568982,185.463968 208.916919,197.637841 206.952008,219 L212.745878,210 Z"
                  transform="matrix(1 0 0 -1 0 400.929)"/>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>