<template>
 <!-- eslint-disable -->
<svg class="celebration crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 385">
    <g fill="none" fill-rule="evenodd" transform="translate(0 20)">
        <ellipse cx="256" cy="315" fill="#F7F6F6" rx="256" ry="50"/>
        <g transform="translate(10)">
            <g transform="translate(8 75)">
                <polygon fill="#DCEAF6" points="64.098 10.715 115.598 131.715 12.598 131.715"
                         transform="scale(1 -1) rotate(-13 -560.95 0)"/>
                <polygon fill="#A8C8E5" points="170.577 27.258 222.077 148.258 119.077 148.258"
                         transform="scale(1 -1) rotate(-5 -1839.419 0)"/>
            </g>
            <g transform="matrix(-1 0 0 1 464 75)">
                <polygon fill="#A8C8E5" points="64.098 10.715 115.598 131.715 12.598 131.715"
                         transform="scale(1 -1) rotate(-13 -560.95 0)"/>
                <polygon fill="#DCEAF6" points="170.577 27.258 222.077 148.258 119.077 148.258"
                         transform="scale(1 -1) rotate(-5 -1839.419 0)"/>
            </g>
            <path fill="#F1F0EF"
                  d="M4.69651757,56.6814149 C82.136477,84.1191329 160.871078,97.8379918 240.900319,97.8379918 C321.000482,97.8379918 401.792573,84.0948071 483.276592,56.6084375 L483.27658,56.6084023 C485.149707,55.9765548 487.180391,56.9828111 487.812239,58.8559388 C487.936581,59.2245552 488,59.6109767 488,60 L488,60 C488,62.9956873 486.085333,65.6561353 483.244725,66.6074982 C401.173244,94.0944949 320.391775,107.837992 240.900319,107.837992 C161.480236,107.837992 82.7577954,94.1191634 4.73299652,66.6815065 L4.73300216,66.6814905 C1.8973549,65.6843265 6.26533898e-13,63.0058662 6.26165786e-13,60 L6.25277607e-13,60 C6.25039483e-13,58.0555649 1.57627607,56.4792889 3.52071115,56.4792889 C3.92129039,56.4792889 4.31893192,56.5476509 4.69651184,56.6814311 Z"/>
            <path fill="#E4E0DE"
                  d="M78,198 L88,198 L88,219 L78,219 L78,198 Z M124.08837,220.14696 L133.904641,218.23887 L137.91163,238.85304 L128.095359,240.76113 L124.08837,220.14696 Z M194.08837,184.14696 L203.904641,182.23887 L207.91163,202.85304 L198.095359,204.76113 L194.08837,184.14696 Z M237.316897,218.468919 L247.242359,219.687612 L244.683103,240.531081 L234.757641,239.312388 L237.316897,218.468919 Z M330.929842,225.580162 L340.875061,224.534878 L343.070158,245.419838 L333.124939,246.465122 L330.929842,225.580162 Z M56.5721596,295.087692 L60.9354462,287.216115 L78.4278404,296.912308 L74.0645538,304.783885 L56.5721596,295.087692 Z M120.57216,288.087692 L124.935446,280.216115 L142.42784,289.912308 L138.064554,297.783885 L120.57216,288.087692 Z M187.57216,308.087692 L191.935446,300.216115 L209.42784,309.912308 L205.064554,317.783885 L187.57216,308.087692 Z M291.389087,265.746952 L297.753048,272.110913 L283.610913,286.253048 L277.246952,279.889087 L291.389087,265.746952 Z M347.389087,295.746952 L353.753048,302.110913 L339.610913,316.253048 L333.246952,309.889087 L347.389087,295.746952 Z M406.929842,225.580162 L416.875061,224.534878 L419.070158,245.419838 L409.124939,246.465122 L406.929842,225.580162 Z M440.08837,147.14696 L449.904641,145.23887 L453.91163,165.85304 L444.095359,167.76113 L440.08837,147.14696 Z M313.252655,2.02775948 L323.100733,0.291277705 L326.747345,20.9722405 L316.899267,22.7087223 L313.252655,2.02775948 Z M192.316897,25.4689187 L202.242359,26.6876121 L199.683103,47.5310813 L189.757641,46.3123879 L192.316897,25.4689187 Z M110.252655,6.02775948 L120.100733,4.29127771 L123.747345,24.9722405 L113.899267,26.7087223 L110.252655,6.02775948 Z M40.3168973,15.4689187 L50.2423589,16.6876121 L47.6831027,37.5310813 L37.7576411,36.3123879 L40.3168973,15.4689187 Z M3.31689735,208.468919 L13.2423589,209.687612 L10.6831027,230.531081 L0.757641136,229.312388 L3.31689735,208.468919 Z"
                  transform="translate(22 9)"/>
            <path fill="#D2CCC8"
                  d="M60,156 L68,156 L68,173 L60,173 L60,156 Z M97,172.498917 L104.704465,171 L107.849418,187.193645 L100.144952,188.692562 L97,172.498917 Z M152,144.498917 L159.704465,143 L162.849418,159.193645 L155.144952,160.692562 L152,144.498917 Z M186.008675,171 L193.79884,171.957355 L191.790165,188.331128 L184,187.373772 L186.008675,171 Z M259,176.821133 L266.805672,176 L268.528531,192.406365 L260.722859,193.227498 L259,176.821133 Z M43,232.183587 L46.4245986,226 L60.1537971,233.61693 L56.7291985,239.800517 L43,232.183587 Z M94,226.183587 L97.4245986,220 L111.153797,227.61693 L107.729199,233.800517 L94,226.183587 Z M146,242.183587 L149.424599,236 L163.153797,243.61693 L159.729199,249.800517 L146,242.183587 Z M228.099692,209 L233.094553,213.999266 L221.994861,225.108746 L217,220.10948 L228.099692,209 Z M272.099692,232 L277.094553,236.999266 L265.994861,248.108746 L261,243.10948 L272.099692,232 Z M319,176.821133 L326.805672,176 L328.528531,192.406365 L320.722859,193.227498 L319,176.821133 Z M345,115.498917 L352.704465,114 L355.849418,130.193645 L348.144952,131.692562 L345,115.498917 Z M245,1.36410866 L252.729429,1.08357767e-13 L255.591533,16.2461138 L247.862104,17.6102225 L245,1.36410866 Z M150.008675,20 L157.79884,20.9573554 L155.790165,37.3311275 L148,36.3737721 L150.008675,20 Z M86,4.36410866 L93.7294286,3 L96.5915329,19.2461138 L88.8621043,20.6102225 L86,4.36410866 Z M31.0086751,12 L38.7988398,12.9573554 L36.7901647,29.3311275 L29,28.3737721 L31.0086751,12 Z M2.0086751,163 L9.79883981,163.957355 L7.7901647,180.331128 L4.50306459e-13,179.373772 L2.0086751,163 Z"
                  transform="rotate(180 216 155)"/>
            <path fill="#BCB4B1"
                  d="M77,215 L88,215 L88,238 L77,238 L77,215 Z M125,238.033284 L135.651906,236 L140,257.966716 L129.348094,260 L125,238.033284 Z M197,199.033284 L207.651906,197 L212,218.966716 L201.348094,221 L197,199.033284 Z M242.664884,236 L253,237.325738 L250.335116,260 L240,258.674262 L242.664884,236 Z M337,243.143938 L346.830273,242 L349,264.856062 L339.169727,266 L337,243.143938 Z M55,319.513316 L59.3920986,311 L77,321.486684 L72.6079014,330 L55,319.513316 Z M122,311.513316 L126.392099,303 L144,313.486684 L139.607901,322 L122,311.513316 Z M190,333.513316 L194.392099,325 L212,335.486684 L207.607901,344 L190,333.513316 Z M297.482759,288 L304,294.827586 L289.517241,310 L283,303.172414 L297.482759,288 Z M353.482759,320 L360,326.827586 L345.517241,342 L339,335.172414 L353.482759,320 Z M415,243.143938 L424.830273,242 L427,264.856062 L417.169727,266 L415,243.143938 Z M448,159.033284 L458.651906,157 L463,178.966716 L452.348094,181 L448,159.033284 Z M319,1.85906839 L328.487066,0 L332,22.1409316 L322.512934,24 L319,1.85906839 Z M194.664884,27 L205,28.3257378 L202.335116,51 L192,49.6742622 L194.664884,27 Z M113,5.85906839 L122.487066,4 L126,26.1409316 L116.512934,28 L113,5.85906839 Z M40.6648845,17 L51,18.3257378 L48.3351155,41 L38,39.6742622 L40.6648845,17 Z M2.6648845,224 L13,225.325738 L10.3351155,248 L0,246.674262 L2.6648845,224 Z"
                  transform="matrix(-1 0 0 1 481 0)"/>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>