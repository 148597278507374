<template>
 <!-- eslint-disable -->
<svg class="boxes crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 385">
    <g fill="none" fill-rule="evenodd" transform="translate(0 76.892)">
        <ellipse cx="256" cy="258.108" fill="#F7F6F6" rx="256" ry="50"/>
        <g transform="translate(159)">
            <polygon fill="#A29894" points="191 31.59 97.518 3.361 97.518 61.164"/>
            <polygon fill="#D2CCC8" points="0 31.59 97.518 3.361 97.518 61.164"/>
            <polygon fill="#BCB4B1" points="191 31.59 151.993 27.557 57.838 0 97.518 3.361"/>
            <polygon fill="#928782" points="156.012 27.973 151.993 27.557 57.838 0 62.795 .42"/>
            <polygon fill="#D2CCC8" points="0 130.393 97.518 164 97.518 61.164 0 31.59"/>
            <polygon fill="#BCB4B1" points="97.518 164 191 131.33 191 31.59 97.518 61.164"/>
            <polygon fill="#EAE8E5" points="148.546 136.188 183.602 123.937 183.602 86.705 148.546 98.551"/>
            <path fill="#BCB4B1"
                  d="M156.700704,116.048226 L156.700704,130.16298 L154.010563,131.065574 L154.010563,116.95082 L156.700704,116.048226 Z M159.390845,115.145633 L159.390845,129.260387 L158.045775,129.711683 L158.045775,115.596929 L159.390845,115.145633 Z M162.080986,114.243039 L162.080986,128.357793 L160.735915,128.80909 L160.735915,114.694336 L162.080986,114.243039 Z M166.116197,112.889149 L166.116197,127.003903 L163.426056,127.906496 L163.426056,113.791742 L166.116197,112.889149 Z M171.496479,111.083962 L171.496479,125.198716 L167.461268,126.552606 L167.461268,112.437852 L171.496479,111.083962 Z M174.18662,110.181368 L174.18662,124.296122 L172.841549,124.747419 L172.841549,110.632665 L174.18662,110.181368 Z M177.549296,109.053126 L177.549296,123.16788 L175.53169,123.844825 L175.53169,109.730071 L177.549296,109.053126 Z"/>
            <polygon fill="#928782"
                     points="150.101 69.902 150.101 44.529 138.416 48.225 138.416 73.262 139.877 70.826 141.337 72.422 142.798 69.986 144.259 71.582 145.719 69.145 147.18 70.742 148.641 68.305"/>
            <polygon fill="#BCB4B1" points="97.518 61.164 132.489 32.262 36.989 3.361 0 31.59"/>
            <polygon fill="#928782" points="128.568 35.503 132.489 32.262 36.989 3.361 33.337 6.148"/>
            <g fill="#BCB4B1" fill-rule="nonzero" transform="rotate(1 -4309.943 755.123)">
                <path d="M16.1946479.243050031C17.8007626.712319621 19.2310603 1.64812217 20.303838 2.93157462L20.2500352 6.29223036C19.381823 5.01470416 18.1248521 4.05074132 16.6654225 3.54321397 14.688169 2.96518118 13.1480634 4.09436151 13.0942606 6.99796806 13.0404577 9.90157462 14.5267606 11.9045254 16.5040141 12.4825582 17.7508512 12.8719146 19.1091465 12.6339801 20.1491549 11.8440336L20.0886268 15.2046894C19.0932746 15.8364927 17.532993 15.9171484 15.9189085 15.4466566 12.5562324 14.4586238 9.63742958 10.7417386 9.7181338 6.03682052 9.79883803 1.33190249 12.7983451-.751704067 16.1946479.243050031zM29.2216549 12.95305L32.584331 19.92305 29.1140493 18.908132 26.127993 12.4287877 25.2671479 12.1733779 25.1662676 17.7520664 21.9851761 16.8245254 22.2474648 2.3333779 27.6277465 3.89944347C30.1365052 4.6078803 31.8148359 6.96451821 31.6629577 9.56550905 31.5553521 11.4810828 30.6810563 12.7984599 29.2216549 12.95305zM26.6525704 10.0897713C27.8698592 10.4460009 28.4280634 9.92173856 28.4482394 8.69845987 28.5911911 7.55468968 27.8441772 6.48858314 26.7198239 6.23173856L25.3747535 5.82845987 25.3075 9.67305003 26.6525704 10.0763287M51.818838 5.13616478C53.8364437 5.72764019 55.1209859 7.82468938 55.7800704 9.10845987L62.9829225 23.7071484C62.3507394 27.6525582 59.6202465 27.7399353 57.9321831 27.3164927L41.5290493 22.5040336 57.6698944 22.1209189 53.5472535 9.35042708C52.6864085 6.80977134 51.3951408 5.86206642 50.3258099 5.54616478 49.6873748 5.340549 49.0004421 5.340549 48.362007 5.54616478 49.4173939 4.96637802 50.6569818 4.81935578 51.818838 5.13616478L51.818838 5.13616478z"/>
                <polygon
                        points="44.327 8.759 38.24 21.556 37.736 21.408 32.154 5.203 35.947 6.306 38.254 14.109 40.93 7.764"/>
                <path d="M59.6807746 7.70370577L59.6807746 8.69845987C59.7567295 9.92787977 59.2238001 11.1162012 58.255 11.8776402L56.6274648 8.58419757 56.5198592 8.38255823C57.5929807 8.25894023 58.6515753 8.03159168 59.6807746 7.70370577L59.6807746 7.70370577zM45.2683451 3.16009921C45.4701056 3.35501724 49.3035563 7.0786238 49.7339789 7.49534511 50.7651115 8.40766015 51.4729476 9.6291229 51.7515845 10.9769845L51.3682394 10.8627222 47.521338 9.74026315C45.8938028 8.98747626 44.8311972 6.33255823 45.1472887 4.09436151L45.2683451 3.16009921z"/>
                <polygon points="37.918 30.173 39.639 32.841 37.844 34.481 36.754 34.166 38.543 32.519 36.828 29.857"/>
                <path d="M33.425 30.0319025C33.425 30.7040336 35.4426056 30.8519025 35.4022535 32.4515746 35.4022535 33.2446894 34.9180282 33.7017386 33.9764789 33.4261648 33.3610084 33.2526117 32.8315749 32.8575708 32.4901761 32.3171484L32.9071479 31.7323943C33.1541317 32.1571645 33.5455386 32.4791656 34.0101056 32.6397713 34.4203521 32.7607549 34.6221127 32.5792795 34.628838 32.3373123 34.628838 31.5778041 32.6112324 31.5173123 32.6515845 29.8907549 32.6258771 29.5557109 32.7808917 29.232393 33.0582823 29.0424945 33.335673 28.8525961 33.6933376 28.8249398 33.9966549 28.9699353 34.5480061 29.1333324 35.0241986 29.4855032 35.3417254 29.9646894L34.9113028 30.5158369C34.6789371 30.1593703 34.3329535 29.8917493 33.9294014 29.7563287 33.6065845 29.655509 33.4317254 29.7563287 33.425 30.0319025zM25.7648239 26.5435418C26.9032584 26.9720534 27.628882 28.09255 27.5537676 29.3060009 27.5537676 30.6099353 26.74 31.3223943 25.6841197 30.9997713 24.5442402 30.5730536 23.8178324 29.451346 23.8951761 28.2373123 23.9019014 26.9400992 24.6887676 26.2410828 25.7648239 26.5435418zM25.6975704 30.1864927C26.3701056 30.3746894 26.7736268 29.8840336 26.7870775 29.0841976 26.862457 28.3482895 26.4474079 27.6496795 25.7648239 27.3635418 25.0922887 27.1753451 24.6887676 27.6592795 24.6753169 28.4658369 24.6064808 29.2041251 25.0261483 29.9013351 25.7110211 30.1864927"/>
                <polygon
                        points="30.95 31.269 31.616 28.338 32.457 28.58 31.38 32.599 30.594 32.364 30.029 29.225 29.363 32.008 28.569 31.779 27.655 27.182 28.482 27.424 29.047 30.717 29.76 27.794 30.352 27.968"/>
                <path d="M22.1600352 26.3217386C21.5278521 26.1335418 21.0570775 26.5973123 21.0436268 27.410591 20.9781869 28.1611045 21.4127161 28.8657659 22.1129577 29.1446894 22.4484856 29.2558589 22.8128607 29.0884646 22.9469014 28.7615746L23.5790845 29.3328861C23.3194273 29.8934922 22.675392 30.1643487 22.0927817 29.9579681 20.944099 29.5343362 20.2071358 28.4104606 20.2769366 27.1887877 20.2769366 25.8445254 21.124331 25.1723943 22.1734859 25.5017386 22.8631944 25.7430716 23.4005278 26.292513 23.626162 26.9871484L22.9536268 27.1820664C22.8301269 26.7917016 22.5393219 26.4764416 22.1600352 26.3217386L22.1600352 26.3217386zM18.5485211 25.9117386C18.5485211 26.6712468 18.145 26.9669845 17.7616549 26.9669845L18.5216197 28.835509 17.6944014 28.5868205 17.0218662 26.8594435 16.4703873 26.698132 16.4703873 28.230591 15.6767958 28.0087877 15.7575 23.7071484 17.3917606 24.1843615C18.1270855 24.4282282 18.6033233 25.1393886 18.5485211 25.9117386zM17.2505282 26.1133779C17.3824098 26.1680805 17.5335303 26.1477479 17.6462412 26.0601365 17.7589521 25.9725252 17.8158698 25.8311474 17.7952817 25.6899353 17.8090721 25.3544426 17.5928913 25.0525252 17.2707042 24.9573123L16.4972887 24.7287877 16.4972887 25.8848533 17.2774296 26.1133779"/>
                <polygon
                        points="12.623 27.115 12.704 22.813 15.179 23.539 15.166 24.339 13.431 23.835 13.417 24.742 15.112 25.24 15.098 26.053 13.404 25.556 13.383 26.523 15.119 27.034 15.098 27.841"/>
                <polygon
                        points="9.422 22.665 9.436 21.859 12.22 22.672 12.207 23.479 11.191 23.183 11.13 26.685 10.377 26.463 10.438 22.961"/>
                <polygon
                        points="6.369 21.771 6.382 20.965 9.173 21.785 9.153 22.585 8.138 22.289 8.077 25.791 7.324 25.569 7.391 22.067"/>
                <polygon
                        points="3.457 24.44 3.538 20.138 6.012 20.857 5.999 21.664 4.271 21.16 4.257 22.067 5.945 22.565 5.932 23.371 4.237 22.88 4.224 23.848 5.952 24.352 5.938 25.166"/>
                <path d="M2.26644366,21.8587877 C2.70245197,22.1212953 2.96034643,22.6006908 2.93897887,23.1089517 C2.93897887,23.7810828 2.56908451,24.1709189 1.90327465,23.9827222 L4.55224025e-13,23.4248533 L0.0807042254,19.1164927 L1.9234507,19.6541976 C2.51424635,19.8439993 2.91058602,20.3985415 2.89862676,21.0186238 C2.89862676,21.5899353 2.60271127,21.8722304 2.26644366,21.8587877 Z M1.70151408,23.1291156 C1.98397887,23.2097713 2.15883803,23.0753451 2.16556338,22.7661648 C2.18314946,22.4685289 2.00243769,22.1948877 1.72169014,22.0940336 L0.759964789,21.8184599 L0.759964789,22.8199353 L1.71496479,23.1022304 M0.807042254,20.0978041 L0.807042254,21.0320664 L1.7418662,21.3076402 C1.99070423,21.3815746 2.15211268,21.2337058 2.15883803,20.958132 C2.17748568,20.6950455 2.01350844,20.4533938 1.76204225,20.3733779 L0.82721831,20.0978041"/>
                <polygon
                        points="71.228 40.719 69.493 40.215 69.48 41.122 71.174 41.613 71.161 42.426 69.466 41.929 69.446 42.903 71.174 43.407 71.161 44.221 68.686 43.495 68.767 39.186 71.242 39.912"/>
                <polygon
                        points="65.693 42.621 65.774 38.313 68.242 39.038 68.229 39.838 66.5 39.334 66.48 40.242 68.182 40.739 68.168 41.552 66.467 41.055 66.433 42.836"/>
                <polygon points="64.254 42.197 64.335 37.896 65.081 38.111 65.001 42.419"/>
                <polygon points="62.331 37.311 62.27 40.806 63.75 41.236 63.736 42.05 61.51 41.398 61.591 37.096"/>
                <path d="M59.6605986,37.9227222 C59.6605986,38.6822304 59.2570775,38.9712468 58.8737324,38.9779681 L59.6336972,40.8464927 L58.7795775,40.5978041 L58.1070423,38.8637058 L57.5622887,38.7091156 L57.5622887,40.2348533 L56.8157746,40.0197713 L56.8964789,35.7114107 L58.5307394,36.1886238 C59.2549175,36.4466849 59.7173646,37.1564449 59.6605986,37.9227222 L59.6605986,37.9227222 Z M58.3626056,38.1243615 C58.6652465,38.2117386 58.8939085,38.0571484 58.9006338,37.7009189 C58.9184174,37.3648546 58.7048237,37.0599039 58.3827817,36.9615746 L57.6026408,36.7397713 L57.6026408,37.8958369 L58.3827817,38.1243615"/>
                <polygon
                        points="53.736 39.126 53.816 34.824 56.291 35.543 56.278 36.35 54.543 35.846 54.529 36.753 56.224 37.244 56.21 38.057 54.516 37.566 54.496 38.534 56.231 39.038 56.21 39.852"/>
                <polygon
                        points="50.534 34.67 50.548 33.87 53.332 34.683 53.319 35.49 52.303 35.187 52.243 38.689 51.489 38.467 51.55 34.972"/>
                <polygon
                        points="47.481 33.782 47.494 32.976 50.279 33.789 50.265 34.596 49.25 34.3 49.189 37.795 48.436 37.58 48.497 34.078"/>
                <polygon
                        points="44.569 36.451 44.65 32.142 47.125 32.868 47.111 33.675 45.376 33.164 45.362 34.071 47.057 34.569 47.044 35.382 45.342 34.885 45.329 35.853 47.064 36.363 47.051 37.17"/>
                <path d="M43.3785211,33.8697713 C43.8156307,34.1312294 44.0739073,34.6113352 44.0510563,35.1199353 C44.0510563,35.7920664 43.681162,36.1819025 43.0086268,35.9869845 L41.1120775,35.4291156 L41.1927817,31.1274763 L43.0355282,31.6651812 C43.6416979,31.8513241 44.0453991,32.4233511 44.0174296,33.0564927 C43.9905282,33.6009189 43.7080634,33.8764927 43.3785211,33.8697713 Z M42.8135915,35.1333779 C43.0960563,35.2140336 43.2709155,35.0728861 43.2776408,34.7637058 C43.2952269,34.4660698 43.1145152,34.1924286 42.8337676,34.0915746 L41.905669,33.8496074 L41.905669,34.8578041 L42.8673944,35.1400992 M41.905669,32.135673 L41.905669,33.0699353 L42.840493,33.3387877 C43.089331,33.4127222 43.2507394,33.2648533 43.2574648,32.9960009 C43.273539,32.7299797 43.1076005,32.4867495 42.8539437,32.4045254 L41.905669,32.135673"/>
            </g>
            <path fill="#D2CCC8"
                  d="M173.177817,103.321348 C173.734963,103.140082 174.18662,103.444521 174.18662,104.001333 C174.18662,104.558145 173.734963,105.156475 173.177817,105.337742 L155.019366,111.245558 C154.46222,111.426825 154.010563,111.122385 154.010563,110.565574 C154.010563,110.008762 154.46222,109.410432 155.019366,109.229165 L173.177817,103.321348 Z M165.107394,101.914258 C165.664541,101.732991 166.116197,102.037431 166.116197,102.594242 C166.116197,103.151054 165.664541,103.749385 165.107394,103.930651 L155.019366,107.212772 C154.46222,107.394038 154.010563,107.089599 154.010563,106.532787 C154.010563,105.975975 154.46222,105.377645 155.019366,105.196378 L165.107394,101.914258 Z M171.832746,99.7261775 C172.389893,99.5449109 172.841549,99.8493505 172.841549,100.406162 C172.841549,100.962974 172.389893,101.561304 171.832746,101.742571 L169.142606,102.617803 C168.585459,102.79907 168.133803,102.49463 168.133803,101.937818 C168.133803,101.381007 168.585459,100.782676 169.142606,100.60141 L171.832746,99.7261775 Z M176.540493,94.1617344 C177.097639,93.9804679 177.549296,94.2849074 177.549296,94.8417191 C177.549296,95.3985307 177.097639,95.9968613 176.540493,96.1781278 L155.019366,103.179985 C154.46222,103.361251 154.010563,103.056812 154.010563,102.5 C154.010563,101.943188 154.46222,101.344858 155.019366,101.163591 L176.540493,94.1617344 Z"/>
        </g>
        <g transform="translate(65 127)">
            <polygon fill="#D2CCC8" points="0 28.469 98.028 0 98.028 58.293"/>
            <polygon fill="#D2CCC8" points="0 128.109 98.028 162 98.028 58.293 0 28.469"/>
            <polygon fill="#BCB4B1" points="98.028 162 192 129.053 192 28.469 98.028 58.293"/>
            <polygon fill="#EAE8E5" points="149.324 133.952 184.563 121.597 184.563 84.05 149.324 95.997"/>
            <path fill="#BCB4B1"
                  d="M157.521127,113.642063 L157.521127,127.876372 L154.816901,128.786611 L154.816901,114.552301 L157.521127,113.642063 Z M160.225352,112.731824 L160.225352,126.966133 L158.873239,127.421253 L158.873239,113.186943 L160.225352,112.731824 Z M162.929577,111.821585 L162.929577,126.055895 L161.577465,126.511014 L161.577465,112.276704 L162.929577,111.821585 Z M166.985915,110.456227 L166.985915,124.690537 L164.28169,125.600775 L164.28169,111.366466 L166.985915,110.456227 Z M172.394366,108.635749 L172.394366,122.870059 L168.338028,124.235417 L168.338028,110.001108 L172.394366,108.635749 Z M175.098592,107.725511 L175.098592,121.95982 L173.746479,122.41494 L173.746479,108.18063 L175.098592,107.725511 Z M178.478873,106.587712 L178.478873,120.822022 L176.450704,121.504701 L176.450704,107.270391 L178.478873,106.587712 Z"/>
            <polygon fill="#928782"
                     points="150.887 67.105 150.887 41.517 139.141 45.245 139.141 70.494 140.609 68.037 142.077 69.646 143.546 67.189 145.014 68.799 146.482 66.342 147.951 67.952 149.419 65.495"/>
            <polygon fill="#BCB4B1" points="98.028 58.293 192 29.146 94.014 1.726 0 28.469"/>
            <polygon fill="#928782" points="139.141 45.569 150.887 41.918 54.744 12.587 42.781 16.042"/>
            <g fill="#BCB4B1" fill-rule="nonzero" transform="rotate(1 -4152.406 757.576)">
                <path d="M16.2794366.245108726C17.8939603.718353148 19.3317465 1.66208218 20.4101408 2.9564058L20.3560563 6.34552714C19.4832985 5.05717997 18.2197466 4.08505212 16.7526761 3.57322588 14.7650704 2.99029701 13.2169014 4.12904178 13.1628169 7.05724262 13.1087324 9.98544345 14.6028169 12.0053598 16.5904225 12.5882886 17.8437876 12.980943 19.2091944 12.7409931 20.2546479 11.9443556L20.1938028 15.3334769C19.1932394 15.9706317 17.6247887 16.0519707 16.0022535 15.5774937 12.6219718 14.581092 9.68788732 10.8327238 9.76901408 6.08795391 9.85014085 1.34318404 12.8653521-.75807119 16.2794366.245108726zM29.3746479 13.0627656L32.7549296 20.0918033 29.2664789 19.0682886 26.2647887 12.5340627 25.3994366 12.2764895 25.2980282 17.9024309 22.1002817 16.9670334 22.3639437 2.3531422 27.7723944 3.93247274C30.294288 4.64691021 31.9814057 7.02350944 31.8287324 9.64653132 31.7205634 11.5783305 30.8416901 12.906866 29.3746479 13.0627656zM26.7921127 10.1752342C28.0157746 10.5344811 28.5769014 10.0057782 28.5971831 8.77213801 28.7408832 7.61867981 27.9899583 6.54354307 26.8597183 6.28452295L25.5076056 5.87782839 25.44 9.7549832 26.7921127 10.1616778M52.0901408 5.1796694C54.1183099 5.77615475 55.4095775 7.89096647 56.0721127 9.18561082L63.3126761 23.9079539C62.6771831 27.8867824 59.9323944 27.9748995 58.235493 27.5478702L41.7464789 22.6946485 57.971831 22.3082886 53.8276056 9.42962755C52.9622535 6.86745182 51.6642254 5.9117196 50.5892958 5.5931422 49.9475181 5.3857848 49.256989 5.3857848 48.6152113 5.5931422 49.6761237 5.00844449 50.9222016 4.86017693 52.0901408 5.1796694L52.0901408 5.1796694z"/>
                <polygon
                        points="44.559 8.833 38.441 21.739 37.934 21.59 32.322 5.247 36.135 6.359 38.454 14.229 41.145 7.83"/>
                <path d="M59.9932394 7.7689581L59.9932394 8.77213801C60.069592 10.0119714 59.5338724 11.2103583 58.56 11.9782468L56.9239437 8.65690789 56.8157746 8.45356061C57.8945147 8.32889554 58.9586516 8.09962129 59.9932394 7.7689581L59.9932394 7.7689581zM45.5053521 3.18686605C45.708169 3.38343509 49.5616901 7.13858153 49.9943662 7.55883258 51.0308974 8.47887515 51.7424395 9.710684 52.0225352 11.0699623L51.6371831 10.9547322 47.7701408 9.82276563C46.1340845 9.06360245 45.0659155 6.38619659 45.383662 4.12904178L45.5053521 3.18686605z"/>
                <polygon points="38.116 30.429 39.847 33.12 38.042 34.773 36.946 34.455 38.745 32.794 37.021 30.11"/>
                <path d="M33.6 30.2862803C33.6 30.9641045 35.628169 31.1132259 35.5876056 32.7264476 35.5876056 33.5262803 35.1008451 33.9872008 34.1543662 33.7092928 33.5356734 33.5342697 33.003468 33.1358827 32.6602817 32.5908828L33.0794366 32.0011757C33.3277136 32.4295437 33.7211697 32.7542723 34.188169 32.9162384 34.6005634 33.0382468 34.8033803 32.8552342 34.8101408 32.6112175 34.8101408 31.8452761 32.7819718 31.7842719 32.8225352 30.1439372 32.7966932 29.8060553 32.9525194 29.4799987 33.2313624 29.2884918 33.5102053 29.0969848 33.8697425 29.0690943 34.1746479 29.2153179 34.7288857 29.3800991 35.2075714 29.7352528 35.5267606 30.2184978L35.0940845 30.7743137C34.8605023 30.4148278 34.5127072 30.14494 34.1070423 30.0083723 33.7825352 29.9066987 33.6067606 30.0083723 33.6 30.2862803zM25.8997183 26.7683723C27.0441132 27.2005134 27.7735358 28.330501 27.6980282 29.5542301 27.6980282 30.8692091 26.88 31.5877029 25.8185915 31.2623472 24.672744 30.8320151 23.9425331 29.7008063 24.0202817 28.4764895 24.0270423 27.1682886 24.8180282 26.4633514 25.8997183 26.7683723zM25.8321127 30.4421799C26.508169 30.6319707 26.9138028 30.1371589 26.9273239 29.3305481 27.0030981 28.5884067 26.585876 27.8838792 25.8997183 27.5953179 25.223662 27.4055271 24.8180282 27.8935606 24.804507 28.7069497 24.7353105 29.4514914 25.1571753 30.1546069 25.8456338 30.4421799"/>
                <polygon
                        points="31.112 31.533 31.781 28.578 32.626 28.822 31.545 32.876 30.754 32.638 30.186 29.473 29.517 32.279 28.719 32.049 27.799 27.412 28.631 27.656 29.199 30.978 29.915 28.029 30.51 28.205"/>
                <path d="M22.2760563 26.5446903C21.6405634 26.3548995 21.1673239 26.8225983 21.1538028 27.6427656 21.0880203 28.3996361 21.5248245 29.1102662 22.2287324 29.3915522 22.5660169 29.5036634 22.9322997 29.3348512 23.0670423 29.0051924L23.7025352 29.581343C23.4415186 30.1466976 22.7941114 30.4198484 22.2084507 30.2117196 21.053754 29.7844995 20.3129323 28.6511043 20.3830986 27.4190836 20.3830986 26.0634351 21.2349296 25.3856108 22.2895775 25.7177447 22.982897 25.9611219 23.5230436 26.5152172 23.7498592 27.2157363L23.0738028 27.4123054C22.9496564 27.018634 22.6573288 26.7007037 22.2760563 26.5446903L22.2760563 26.5446903zM18.6456338 26.1312175C18.6456338 26.8971589 18.24 27.1954016 17.8546479 27.1954016L18.6185915 29.0797531 17.7870423 28.8289581 17.1109859 27.0869497 16.5566197 26.9242719 16.5566197 28.4697112 15.7588732 28.2460292 15.84 23.9079539 17.4828169 24.3892091C18.2219917 24.6351415 18.7007229 25.3523255 18.6456338 26.1312175zM17.3408451 26.3345648C17.4734171 26.3897307 17.6253289 26.3692259 17.7386299 26.2808724 17.8519309 26.192519 17.9091466 26.0499438 17.8884507 25.9075355 17.9023134 25.5692011 17.6850007 25.2647264 17.3611268 25.1687071L16.583662 24.9382468 16.583662 26.1041045 17.3678873 26.3345648"/>
                <polygon
                        points="12.69 27.345 12.771 23.006 15.259 23.738 15.245 24.545 13.501 24.037 13.487 24.952 15.191 25.453 15.177 26.274 13.474 25.772 13.454 26.748 15.198 27.263 15.177 28.077"/>
                <polygon
                        points="9.472 22.857 9.485 22.044 12.284 22.864 12.27 23.677 11.25 23.379 11.189 26.911 10.432 26.687 10.492 23.156"/>
                <polygon
                        points="6.402 21.956 6.416 21.142 9.221 21.969 9.201 22.776 8.18 22.478 8.119 26.009 7.362 25.786 7.43 22.254"/>
                <polygon
                        points="3.475 24.647 3.556 20.309 6.044 21.034 6.03 21.847 4.293 21.339 4.279 22.254 5.976 22.756 5.963 23.569 4.259 23.074 4.246 24.05 5.983 24.559 5.97 25.379"/>
                <path d="M2.27830986,22.0439372 C2.71660093,22.3086683 2.97584563,22.7921244 2.9543662,23.3046903 C2.9543662,23.9825146 2.58253521,24.3756527 1.91323944,24.1858619 L0,23.6232677 L0.0811267606,19.2784142 L1.93352113,19.8206736 C2.52740995,20.012083 2.92582469,20.5713223 2.91380282,21.1966568 C2.91380282,21.7728075 2.61633803,22.0574937 2.27830986,22.0439372 Z M1.71042254,23.325025 C1.9943662,23.406364 2.17014085,23.2707991 2.17690141,22.9589999 C2.19457956,22.658843 2.01292166,22.382884 1.73070423,22.2811757 L0.763943662,22.0032677 L0.763943662,23.0132259 L1.72394366,23.2979121 M0.811267606,20.2680376 L0.811267606,21.2102133 L1.75098592,21.4881213 C2.00112676,21.5626819 2.16338028,21.4135606 2.17014085,21.1356527 C2.18888612,20.8703377 2.02405036,20.6266392 1.77126761,20.5459455 L0.831549296,20.2680376"/>
                <polygon
                        points="71.601 41.064 69.857 40.555 69.843 41.47 71.547 41.965 71.534 42.785 69.83 42.284 69.81 43.267 71.547 43.775 71.534 44.595 69.046 43.863 69.127 39.518 71.615 40.25"/>
                <polygon
                        points="66.037 42.982 66.118 38.637 68.599 39.369 68.586 40.176 66.848 39.667 66.828 40.582 68.539 41.084 68.525 41.904 66.815 41.403 66.781 43.199"/>
                <polygon points="64.59 42.555 64.672 38.217 65.422 38.434 65.341 42.779"/>
                <polygon points="62.657 37.627 62.596 41.152 64.083 41.586 64.07 42.406 61.832 41.748 61.913 37.41"/>
                <path d="M59.9729577,38.2439372 C59.9729577,39.0098786 59.5673239,39.301343 59.1819718,39.3081213 L59.9459155,41.1924727 L59.0873239,40.9416778 L58.4112676,39.1928912 L57.863662,39.0369916 L57.863662,40.5756527 L57.1132394,40.3587489 L57.1943662,36.0138953 L58.8371831,36.4951506 C59.5651527,36.7553975 60.030021,37.4711693 59.9729577,38.2439372 L59.9729577,38.2439372 Z M58.668169,38.4472845 C58.9723944,38.5354016 59.2022535,38.379502 59.2090141,38.0202552 C59.2268908,37.6813443 59.0121788,37.3738107 58.6884507,37.2746485 L57.9042254,37.0509665 L57.9042254,38.2168242 L58.6884507,38.4472845"/>
                <polygon
                        points="54.017 39.457 54.098 35.119 56.586 35.844 56.572 36.658 54.828 36.149 54.815 37.065 56.518 37.559 56.505 38.38 54.801 37.885 54.781 38.861 56.525 39.369 56.505 40.189"/>
                <polygon
                        points="50.799 34.963 50.812 34.157 53.611 34.977 53.598 35.79 52.577 35.485 52.516 39.017 51.759 38.793 51.82 35.268"/>
                <polygon
                        points="47.73 34.069 47.743 33.255 50.542 34.075 50.528 34.889 49.508 34.59 49.447 38.115 48.69 37.898 48.75 34.367"/>
                <polygon
                        points="44.802 36.76 44.883 32.415 47.371 33.147 47.358 33.96 45.614 33.445 45.6 34.36 47.304 34.862 47.29 35.682 45.58 35.18 45.566 36.156 47.31 36.671 47.297 37.485"/>
                <path d="M43.6056338,34.1566568 C44.0450319,34.4203295 44.3046607,34.904502 44.2816901,35.41741 C44.2816901,36.0952342 43.9098592,36.4883723 43.2338028,36.2918033 L41.3273239,35.7292091 L41.4084507,31.3911338 L43.2608451,31.9333932 C43.8701884,32.1211129 44.2760033,32.6979851 44.2478873,33.3364895 C44.2208451,33.8855271 43.9369014,34.1634351 43.6056338,34.1566568 Z M43.0377465,35.4309665 C43.3216901,35.5123054 43.4974648,35.3699623 43.5042254,35.0581631 C43.5219035,34.7580061 43.3402456,34.4820471 43.0580282,34.3803389 L42.1250704,34.1363221 L42.1250704,35.1530585 L43.091831,35.4377447 M42.1250704,32.4078702 L42.1250704,33.350046 L43.0647887,33.6211757 C43.3149296,33.6957363 43.4771831,33.546615 43.4839437,33.2754853 C43.500102,33.0072109 43.3332948,32.7619205 43.0783099,32.6789999 L42.1250704,32.4078702"/>
            </g>
            <path fill="#D2CCC8"
                  d="M174.084507,100.807385 C174.64457,100.624583 175.098592,100.931601 175.098592,101.493129 C175.098592,102.054657 174.64457,102.658056 174.084507,102.840858 L155.830986,108.798715 C155.270923,108.981517 154.816901,108.674499 154.816901,108.112971 C154.816901,107.551443 155.270923,106.948044 155.830986,106.765242 L174.084507,100.807385 Z M165.971831,99.3883758 C166.531894,99.205574 166.985915,99.5125921 166.985915,100.07412 C166.985915,100.635648 166.531894,101.239047 165.971831,101.421849 L155.830986,104.731769 C155.270923,104.914571 154.816901,104.607553 154.816901,104.046025 C154.816901,103.484497 155.270923,102.881099 155.830986,102.698297 L165.971831,99.3883758 Z M172.732394,97.181762 C173.292458,96.9989601 173.746479,97.3059783 173.746479,97.8675063 C173.746479,98.4290343 173.292458,99.0324329 172.732394,99.2152348 L170.028169,100.09788 C169.468106,100.280682 169.014085,99.973664 169.014085,99.412136 C169.014085,98.850608 169.468106,98.2472094 170.028169,98.0644075 L172.732394,97.181762 Z M177.464789,91.5701867 C178.024852,91.3873848 178.478873,91.694403 178.478873,92.255931 C178.478873,92.817459 178.024852,93.4208576 177.464789,93.6036595 L155.830986,100.664824 C155.270923,100.847626 154.816901,100.540608 154.816901,99.9790795 C154.816901,99.4175515 155.270923,98.8141529 155.830986,98.631351 L177.464789,91.5701867 Z"/>
        </g>
        <g transform="translate(257 128)">
            <path fill="#A29894" d="M98.028169,0 L98.028169,58.292887 L98.028169,0 Z"/>
            <polygon fill="#BCB4B1" points="98.028 58.293 192 29.146 92.4 1.628 0 28.469"/>
            <polygon fill="#928782" points="140.341 45.569 152.087 41.918 56.318 12.272 43.981 16.042"/>
            <polygon fill="#D2CCC8" points="0 128.109 98.028 162 98.028 58.293 0 28.469"/>
            <polygon fill="#BCB4B1" points="98.028 162 192 129.053 192 28.469 98.028 58.293"/>
            <polygon fill="#EAE8E5" points="149.324 133.952 184.563 121.597 184.563 84.05 149.324 95.997"/>
            <path fill="#BCB4B1"
                  d="M157.521127,113.642063 L157.521127,127.876372 L154.816901,128.786611 L154.816901,114.552301 L157.521127,113.642063 Z M160.225352,112.731824 L160.225352,126.966133 L158.873239,127.421253 L158.873239,113.186943 L160.225352,112.731824 Z M162.929577,111.821585 L162.929577,126.055895 L161.577465,126.511014 L161.577465,112.276704 L162.929577,111.821585 Z M166.985915,110.456227 L166.985915,124.690537 L164.28169,125.600775 L164.28169,111.366466 L166.985915,110.456227 Z M172.394366,108.635749 L172.394366,122.870059 L168.338028,124.235417 L168.338028,110.001108 L172.394366,108.635749 Z M175.098592,107.725511 L175.098592,121.95982 L173.746479,122.41494 L173.746479,108.18063 L175.098592,107.725511 Z M178.478873,106.587712 L178.478873,120.822022 L176.450704,121.504701 L176.450704,107.270391 L178.478873,106.587712 Z"/>
            <polygon fill="#928782"
                     points="150.887 67.105 150.887 41.517 139.141 45.245 139.141 70.494 140.609 68.037 142.077 69.646 143.546 67.189 145.014 68.799 146.482 66.342 147.951 67.952 149.419 65.495"/>
            <g fill="#BCB4B1" fill-rule="nonzero" transform="rotate(1 -4152.406 757.576)">
                <path d="M16.2794366.245108726C17.8939603.718353148 19.3317465 1.66208218 20.4101408 2.9564058L20.3560563 6.34552714C19.4832985 5.05717997 18.2197466 4.08505212 16.7526761 3.57322588 14.7650704 2.99029701 13.2169014 4.12904178 13.1628169 7.05724262 13.1087324 9.98544345 14.6028169 12.0053598 16.5904225 12.5882886 17.8437876 12.980943 19.2091944 12.7409931 20.2546479 11.9443556L20.1938028 15.3334769C19.1932394 15.9706317 17.6247887 16.0519707 16.0022535 15.5774937 12.6219718 14.581092 9.68788732 10.8327238 9.76901408 6.08795391 9.85014085 1.34318404 12.8653521-.75807119 16.2794366.245108726zM29.3746479 13.0627656L32.7549296 20.0918033 29.2664789 19.0682886 26.2647887 12.5340627 25.3994366 12.2764895 25.2980282 17.9024309 22.1002817 16.9670334 22.3639437 2.3531422 27.7723944 3.93247274C30.294288 4.64691021 31.9814057 7.02350944 31.8287324 9.64653132 31.7205634 11.5783305 30.8416901 12.906866 29.3746479 13.0627656zM26.7921127 10.1752342C28.0157746 10.5344811 28.5769014 10.0057782 28.5971831 8.77213801 28.7408832 7.61867981 27.9899583 6.54354307 26.8597183 6.28452295L25.5076056 5.87782839 25.44 9.7549832 26.7921127 10.1616778M52.0901408 5.1796694C54.1183099 5.77615475 55.4095775 7.89096647 56.0721127 9.18561082L63.3126761 23.9079539C62.6771831 27.8867824 59.9323944 27.9748995 58.235493 27.5478702L41.7464789 22.6946485 57.971831 22.3082886 53.8276056 9.42962755C52.9622535 6.86745182 51.6642254 5.9117196 50.5892958 5.5931422 49.9475181 5.3857848 49.256989 5.3857848 48.6152113 5.5931422 49.6761237 5.00844449 50.9222016 4.86017693 52.0901408 5.1796694L52.0901408 5.1796694z"/>
                <polygon
                        points="44.559 8.833 38.441 21.739 37.934 21.59 32.322 5.247 36.135 6.359 38.454 14.229 41.145 7.83"/>
                <path d="M59.9932394 7.7689581L59.9932394 8.77213801C60.069592 10.0119714 59.5338724 11.2103583 58.56 11.9782468L56.9239437 8.65690789 56.8157746 8.45356061C57.8945147 8.32889554 58.9586516 8.09962129 59.9932394 7.7689581L59.9932394 7.7689581zM45.5053521 3.18686605C45.708169 3.38343509 49.5616901 7.13858153 49.9943662 7.55883258 51.0308974 8.47887515 51.7424395 9.710684 52.0225352 11.0699623L51.6371831 10.9547322 47.7701408 9.82276563C46.1340845 9.06360245 45.0659155 6.38619659 45.383662 4.12904178L45.5053521 3.18686605z"/>
                <polygon points="38.116 30.429 39.847 33.12 38.042 34.773 36.946 34.455 38.745 32.794 37.021 30.11"/>
                <path d="M33.6 30.2862803C33.6 30.9641045 35.628169 31.1132259 35.5876056 32.7264476 35.5876056 33.5262803 35.1008451 33.9872008 34.1543662 33.7092928 33.5356734 33.5342697 33.003468 33.1358827 32.6602817 32.5908828L33.0794366 32.0011757C33.3277136 32.4295437 33.7211697 32.7542723 34.188169 32.9162384 34.6005634 33.0382468 34.8033803 32.8552342 34.8101408 32.6112175 34.8101408 31.8452761 32.7819718 31.7842719 32.8225352 30.1439372 32.7966932 29.8060553 32.9525194 29.4799987 33.2313624 29.2884918 33.5102053 29.0969848 33.8697425 29.0690943 34.1746479 29.2153179 34.7288857 29.3800991 35.2075714 29.7352528 35.5267606 30.2184978L35.0940845 30.7743137C34.8605023 30.4148278 34.5127072 30.14494 34.1070423 30.0083723 33.7825352 29.9066987 33.6067606 30.0083723 33.6 30.2862803zM25.8997183 26.7683723C27.0441132 27.2005134 27.7735358 28.330501 27.6980282 29.5542301 27.6980282 30.8692091 26.88 31.5877029 25.8185915 31.2623472 24.672744 30.8320151 23.9425331 29.7008063 24.0202817 28.4764895 24.0270423 27.1682886 24.8180282 26.4633514 25.8997183 26.7683723zM25.8321127 30.4421799C26.508169 30.6319707 26.9138028 30.1371589 26.9273239 29.3305481 27.0030981 28.5884067 26.585876 27.8838792 25.8997183 27.5953179 25.223662 27.4055271 24.8180282 27.8935606 24.804507 28.7069497 24.7353105 29.4514914 25.1571753 30.1546069 25.8456338 30.4421799"/>
                <polygon
                        points="31.112 31.533 31.781 28.578 32.626 28.822 31.545 32.876 30.754 32.638 30.186 29.473 29.517 32.279 28.719 32.049 27.799 27.412 28.631 27.656 29.199 30.978 29.915 28.029 30.51 28.205"/>
                <path d="M22.2760563 26.5446903C21.6405634 26.3548995 21.1673239 26.8225983 21.1538028 27.6427656 21.0880203 28.3996361 21.5248245 29.1102662 22.2287324 29.3915522 22.5660169 29.5036634 22.9322997 29.3348512 23.0670423 29.0051924L23.7025352 29.581343C23.4415186 30.1466976 22.7941114 30.4198484 22.2084507 30.2117196 21.053754 29.7844995 20.3129323 28.6511043 20.3830986 27.4190836 20.3830986 26.0634351 21.2349296 25.3856108 22.2895775 25.7177447 22.982897 25.9611219 23.5230436 26.5152172 23.7498592 27.2157363L23.0738028 27.4123054C22.9496564 27.018634 22.6573288 26.7007037 22.2760563 26.5446903L22.2760563 26.5446903zM18.6456338 26.1312175C18.6456338 26.8971589 18.24 27.1954016 17.8546479 27.1954016L18.6185915 29.0797531 17.7870423 28.8289581 17.1109859 27.0869497 16.5566197 26.9242719 16.5566197 28.4697112 15.7588732 28.2460292 15.84 23.9079539 17.4828169 24.3892091C18.2219917 24.6351415 18.7007229 25.3523255 18.6456338 26.1312175zM17.3408451 26.3345648C17.4734171 26.3897307 17.6253289 26.3692259 17.7386299 26.2808724 17.8519309 26.192519 17.9091466 26.0499438 17.8884507 25.9075355 17.9023134 25.5692011 17.6850007 25.2647264 17.3611268 25.1687071L16.583662 24.9382468 16.583662 26.1041045 17.3678873 26.3345648"/>
                <polygon
                        points="12.69 27.345 12.771 23.006 15.259 23.738 15.245 24.545 13.501 24.037 13.487 24.952 15.191 25.453 15.177 26.274 13.474 25.772 13.454 26.748 15.198 27.263 15.177 28.077"/>
                <polygon
                        points="9.472 22.857 9.485 22.044 12.284 22.864 12.27 23.677 11.25 23.379 11.189 26.911 10.432 26.687 10.492 23.156"/>
                <polygon
                        points="6.402 21.956 6.416 21.142 9.221 21.969 9.201 22.776 8.18 22.478 8.119 26.009 7.362 25.786 7.43 22.254"/>
                <polygon
                        points="3.475 24.647 3.556 20.309 6.044 21.034 6.03 21.847 4.293 21.339 4.279 22.254 5.976 22.756 5.963 23.569 4.259 23.074 4.246 24.05 5.983 24.559 5.97 25.379"/>
                <path d="M2.27830986,22.0439372 C2.71660093,22.3086683 2.97584563,22.7921244 2.9543662,23.3046903 C2.9543662,23.9825146 2.58253521,24.3756527 1.91323944,24.1858619 L-4.54747351e-13,23.6232677 L0.0811267606,19.2784142 L1.93352113,19.8206736 C2.52740995,20.012083 2.92582469,20.5713223 2.91380282,21.1966568 C2.91380282,21.7728075 2.61633803,22.0574937 2.27830986,22.0439372 Z M1.71042254,23.325025 C1.9943662,23.406364 2.17014085,23.2707991 2.17690141,22.9589999 C2.19457956,22.658843 2.01292166,22.382884 1.73070423,22.2811757 L0.763943662,22.0032677 L0.763943662,23.0132259 L1.72394366,23.2979121 M0.811267606,20.2680376 L0.811267606,21.2102133 L1.75098592,21.4881213 C2.00112676,21.5626819 2.16338028,21.4135606 2.17014085,21.1356527 C2.18888612,20.8703377 2.02405036,20.6266392 1.77126761,20.5459455 L0.831549296,20.2680376"/>
                <polygon
                        points="71.601 41.064 69.857 40.555 69.843 41.47 71.547 41.965 71.534 42.785 69.83 42.284 69.81 43.267 71.547 43.775 71.534 44.595 69.046 43.863 69.127 39.518 71.615 40.25"/>
                <polygon
                        points="66.037 42.982 66.118 38.637 68.599 39.369 68.586 40.176 66.848 39.667 66.828 40.582 68.539 41.084 68.525 41.904 66.815 41.403 66.781 43.199"/>
                <polygon points="64.59 42.555 64.672 38.217 65.422 38.434 65.341 42.779"/>
                <polygon points="62.657 37.627 62.596 41.152 64.083 41.586 64.07 42.406 61.832 41.748 61.913 37.41"/>
                <path d="M59.9729577,38.2439372 C59.9729577,39.0098786 59.5673239,39.301343 59.1819718,39.3081213 L59.9459155,41.1924727 L59.0873239,40.9416778 L58.4112676,39.1928912 L57.863662,39.0369916 L57.863662,40.5756527 L57.1132394,40.3587489 L57.1943662,36.0138953 L58.8371831,36.4951506 C59.5651527,36.7553975 60.030021,37.4711693 59.9729577,38.2439372 L59.9729577,38.2439372 Z M58.668169,38.4472845 C58.9723944,38.5354016 59.2022535,38.379502 59.2090141,38.0202552 C59.2268908,37.6813443 59.0121788,37.3738107 58.6884507,37.2746485 L57.9042254,37.0509665 L57.9042254,38.2168242 L58.6884507,38.4472845"/>
                <polygon
                        points="54.017 39.457 54.098 35.119 56.586 35.844 56.572 36.658 54.828 36.149 54.815 37.065 56.518 37.559 56.505 38.38 54.801 37.885 54.781 38.861 56.525 39.369 56.505 40.189"/>
                <polygon
                        points="50.799 34.963 50.812 34.157 53.611 34.977 53.598 35.79 52.577 35.485 52.516 39.017 51.759 38.793 51.82 35.268"/>
                <polygon
                        points="47.73 34.069 47.743 33.255 50.542 34.075 50.528 34.889 49.508 34.59 49.447 38.115 48.69 37.898 48.75 34.367"/>
                <polygon
                        points="44.802 36.76 44.883 32.415 47.371 33.147 47.358 33.96 45.614 33.445 45.6 34.36 47.304 34.862 47.29 35.682 45.58 35.18 45.566 36.156 47.31 36.671 47.297 37.485"/>
                <path d="M43.6056338,34.1566568 C44.0450319,34.4203295 44.3046607,34.904502 44.2816901,35.41741 C44.2816901,36.0952342 43.9098592,36.4883723 43.2338028,36.2918033 L41.3273239,35.7292091 L41.4084507,31.3911338 L43.2608451,31.9333932 C43.8701884,32.1211129 44.2760033,32.6979851 44.2478873,33.3364895 C44.2208451,33.8855271 43.9369014,34.1634351 43.6056338,34.1566568 Z M43.0377465,35.4309665 C43.3216901,35.5123054 43.4974648,35.3699623 43.5042254,35.0581631 C43.5219035,34.7580061 43.3402456,34.4820471 43.0580282,34.3803389 L42.1250704,34.1363221 L42.1250704,35.1530585 L43.091831,35.4377447 M42.1250704,32.4078702 L42.1250704,33.350046 L43.0647887,33.6211757 C43.3149296,33.6957363 43.4771831,33.546615 43.4839437,33.2754853 C43.500102,33.0072109 43.3332948,32.7619205 43.0783099,32.6789999 L42.1250704,32.4078702"/>
            </g>
            <path fill="#D2CCC8"
                  d="M174.084507,100.807385 C174.64457,100.624583 175.098592,100.931601 175.098592,101.493129 C175.098592,102.054657 174.64457,102.658056 174.084507,102.840858 L155.830986,108.798715 C155.270923,108.981517 154.816901,108.674499 154.816901,108.112971 C154.816901,107.551443 155.270923,106.948044 155.830986,106.765242 L174.084507,100.807385 Z M165.971831,99.3883758 C166.531894,99.205574 166.985915,99.5125921 166.985915,100.07412 C166.985915,100.635648 166.531894,101.239047 165.971831,101.421849 L155.830986,104.731769 C155.270923,104.914571 154.816901,104.607553 154.816901,104.046025 C154.816901,103.484497 155.270923,102.881099 155.830986,102.698297 L165.971831,99.3883758 Z M172.732394,97.181762 C173.292458,96.9989601 173.746479,97.3059783 173.746479,97.8675063 C173.746479,98.4290343 173.292458,99.0324329 172.732394,99.2152348 L170.028169,100.09788 C169.468106,100.280682 169.014085,99.973664 169.014085,99.412136 C169.014085,98.850608 169.468106,98.2472094 170.028169,98.0644075 L172.732394,97.181762 Z M177.464789,91.5701867 C178.024852,91.3873848 178.478873,91.694403 178.478873,92.255931 C178.478873,92.817459 178.024852,93.4208576 177.464789,93.6036595 L155.830986,100.664824 C155.270923,100.847626 154.816901,100.540608 154.816901,99.9790795 C154.816901,99.4175515 155.270923,98.8141529 155.830986,98.631351 L177.464789,91.5701867 Z"/>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>