<template>
 <!-- eslint-disable -->
<svg class="tools crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 385">
    <defs>
        <path id="tools-a"
              d="M16.302384,0 L151.000026,0 C157.075158,-2.89234053e-15 162.000026,4.92486775 162.000026,11 L162.000026,38.0000104 C162.000026,44.0751426 157.075158,49.0000104 151.000026,49.0000104 L16.3559166,49.0000104 C7.76870458,49.0000132 0.712752683,42.2212752 0.368756758,33.640956 C0.122918919,27.5090022 0,24.1353502 0,23.52 C0,22.9492369 0.10575133,20.2126866 0.317253989,15.3103491 C0.686576857,6.74997812 7.73404989,1.57397745e-15 16.302384,0 Z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <rect width="512" height="385" fill="#FFF"/>
        <g transform="translate(0 60)">
            <ellipse cx="256" cy="275" fill="#F7F6F6" rx="256" ry="50"/>
            <g transform="rotate(-32 441.338 43.531)">
                <g transform="translate(0 6)">
                    <mask id="tools-b" fill="#fff">
                        <use xlink:href="#tools-a"/>
                    </mask>
                    <use fill="#DCEAF6" xlink:href="#tools-a"/>
                    <rect width="138" height="7" x="9" y="-5" fill="#75A3CD" mask="url(#tools-b)" rx="3.5"/>
                    <rect width="138" height="7" x="9" y="47" fill="#75A3CD" mask="url(#tools-b)" rx="3.5"/>
                </g>
                <path fill="#CEE0F1"
                      d="M150.47017,0 L158.000049,0 C160.209188,-4.05812251e-16 162.000049,1.790861 162.000049,4 L162.000049,56.0000129 C162.000049,58.2091519 160.209188,60.0000129 158.000049,60.0000129 L150.491113,60.0000129 C148.4874,60.0000208 146.792833,58.5174828 146.526577,56.5315389 C144.823774,43.8307042 143.972373,34.8268579 143.972373,29.52 C143.972373,24.231748 144.817815,15.5404621 146.508698,3.44614208 C146.78496,1.47022355 148.475032,3.66500855e-16 150.47017,0 Z"/>
                <ellipse cx="159.5" cy="29.5" fill="#BDD6ED" rx="4.5" ry="29.5"/>
                <path fill="#BCB4B1"
                      d="M160,23 L415,23 L426,18 L452,23 L452,37 L426,41.13 L415,36.7 L160,37 C159.483749,34.2167478 159.225624,31.7384145 159.225624,29.565 C159.225624,27.3915855 159.483749,25.2032522 160,23 Z"/>
                <g fill="#A8C8E5" transform="translate(4 14)">
                    <rect width="138" height="7" x="2" rx="3.5"/>
                    <rect width="138" height="7" y="13" rx="3.5"/>
                    <rect width="138" height="7" x="2" y="26" rx="3.5"/>
                </g>
            </g>
            <g transform="translate(66 7)">
                <path fill="#E4E0DE"
                      d="M112.194721,9.62861453 C123.376639,18.2436495 129.679354,28.8044961 131.102868,41.3111542 C132.486839,53.4704052 132.525282,64.0164927 131.218198,72.9494169 L131.102868,73.7111455 L368.736842,205.311154 C387.578947,217.119223 392.333333,231.75421 383,249.216115 C373.966787,265.279264 359.474171,265.97761 339.522152,251.311154 C260.998612,206.644488 183.698329,161.977821 107.621302,117.311154 C93.2018087,125.64641 75.7653057,125.079349 55.3117934,115.60997 L54.671639,115.311154 L80.713814,55.1277209 C83.7323362,47.6814406 82.710785,42.1270757 77.6491601,38.4646262 C74.5195446,36.2001244 55.6542679,30.831156 21.0533298,22.357721 L20,22.1 L24,14.0911996 C39.3675663,6.4128969 55.2602202,1.83524529 71.6779616,0.358244721 C88.095703,-1.11875584 101.601289,1.97136743 112.194721,9.62861453 Z M361.5,216 C352.387302,216 345,223.387302 345,232.5 C345,241.612698 352.387302,249 361.5,249 C370.612698,249 378,241.612698 378,232.5 C378,223.387302 370.612698,216 361.5,216 Z M7,47 L54.4924166,71.4624339 C57.0531386,73.0640416 59.7077959,74.0052155 62.4563884,74.2859557 C65.204981,74.5666958 68.1747506,73.4713772 71.3656973,71 L71.3656973,71 L51.3832869,115 C30.1277623,99.4535081 17.7230681,89.6611884 14.1692043,85.6230411 C8.83840859,79.5658201 5,73.164532 4,58.6405071 C4,57.1978386 3.66666667,55.6510029 3,54 L3,54 L7,47 Z"/>
                <path fill="#F1F0EF"
                      d="M67.6779616,8.35824472 C84.095703,6.88124416 97.6012893,9.97136743 108.194721,17.6286145 C119.376639,26.2436495 125.679354,36.8044961 127.102868,49.3111542 C128.526381,61.8178124 128.526381,72.6178094 127.102868,81.7111455 L127.102868,81.7111455 L364.736842,213.311154 C384.436721,224.712055 389.770055,238.44408 380.736842,254.507229 C371.70363,270.570378 356.632066,272.171686 335.522152,259.311154 C256.998612,214.644488 179.698329,169.977821 103.621302,125.311154 C89.0516057,133.733236 71.4017178,133.066569 50.671639,123.311154 L50.671639,123.311154 L76.713814,63.1277209 C79.7323362,55.6814406 78.710785,50.1270757 73.6491601,46.4646262 C68.5875352,42.8021768 50.7044819,34.6777012 20,22.0911996 C35.3675663,14.4128969 51.2602202,9.83524529 67.6779616,8.35824472 Z M357.5,223 C348.387302,223 341,230.387302 341,239.5 C341,248.612698 348.387302,256 357.5,256 C366.612698,256 374,248.612698 374,239.5 C374,230.387302 366.612698,223 357.5,223 Z"/>
                <path fill="#F1F0EF"
                      d="M10.1692043,92.6230411 C13.7230681,96.6611884 26.6666667,106.453508 49,122 L68,78.4624339 C64.3861849,80.6255219 61.204981,81.5666958 58.4563884,81.2859557 C55.7077959,81.0052155 53.0531386,80.0640416 50.4924166,78.4624339 L3,54 C1,56.809688 0,60.689857 0,65.6405071 C1,80.164532 4.83840859,86.5658201 10.1692043,92.6230411 Z"/>
                <rect width="26" height="29" x="81.041" y="76.231" fill="#E4E0DE" rx="5"
                      transform="rotate(26 94.04 90.731)"/>
                <line x1="81.5" x2="100.5" y1="88.5" y2="105.5" stroke="#BCB4B1" stroke-linecap="round"
                      stroke-width="3"/>
                <line x1="84.5" x2="103.5" y1="82.5" y2="99.5" stroke="#BCB4B1" stroke-linecap="round"
                      stroke-width="3"/>
                <line x1="87.5" x2="106.5" y1="75.5" y2="92.5" stroke="#BCB4B1" stroke-linecap="round"
                      stroke-width="3"/>
            </g>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>