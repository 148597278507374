<template>
    <v-bottom-navigation
        v-model="showBottomMenu"
        active
        grow
        bg-color="base-gray-5"
    >
        <v-btn 
            v-for="(item) in bottomMenu"
            :key="item.key"
            :active="getActive(item.key)"
            @click="handleClickMenuItem(item)"
        >
            <div class="d-flex flex-column align-center">
                <v-icon
                    :icon="item.iconName"
                    color="base-gray-70"
                />
                <div class="text-base-gray-70">
                    {{ item.label }}
                </div>
            </div>
        </v-btn>
        <v-btn 
            v-if="moreMenu"
            @click="handleClickMore"
        >
            <div class="d-flex flex-column align-center">
                <v-icon
                    :icon="mdiDotsHorizontal"
                    color="base-gray-70"
                    size="large"
                />
                <div class="text-base-gray-70">
                    {{ moreMenuLabel }}
                </div>
            </div>
        </v-btn>
    </v-bottom-navigation>
    
    <v-navigation-drawer
        class="more-menu"
        v-model="showMoreMenu" 
        location="bottom" 
        temporary
        color="transparent"
        :width="moreMenuHeight"
    >
        <div ref="moreMenuContent" class="more-menu_content">
            <div
                v-if="accountName"
                class="more-menu_header"
            >
                <div class="more-menu_account">
                    <div class="more-menu_account-label">
                        {{ accountLabel }}
                    </div>
                    <div class="more-menu_account-name">
                        {{ truncateAccountName }}
                    </div>
                    <div
                        v-if="customerNumber"
                        class="more-menu_customer-number"
                    >
                        <span class="more-menu_customer-number-label">{{ customerNumberLabel }}</span>
                        <span>{{ customerNumber }}</span>
                    </div>
                    <div
                        v-if="hasSingleLocation"
                        class="more-menu_location-name"
                    >
                        {{ singleLocationName }}
                    </div>
                </div>
                <div
                    class="more-menu_logout"
                    @click="handleClickLogout"
                >
                    <span class="mr-2">
                        {{ logoutLabel }}
                    </span>
                    <v-icon
                        :icon="mdiLogout"
                        size="small"
                    />
                </div>
            </div>
            <slot name="customer-name">
                <crv-app-nav-item
                    v-if="allowSwitchCustomer"
                    class="more-menu_item"
                    :should-show-chevron="allowSwitchCustomer"
                    @click="handleClickCustomer"
                >
                    {{ customerName }}
                </crv-app-nav-item>
            </slot>
            <slot
                v-if="hasMultipleLocations"
                name="locations"
            >
                <div class="more-menu_locations">
                    <!-- TODO there is no modal variant of a vuetify select ?? -->
                    <!-- <crv-select-single
                        v-model="selectedLocationId"
                        select-type="modal"
                        :heading-text="locationsLabel"
                        :maximize-modal="false"
                        :select-options="locationOptions"
                        @input="handleChangeLocation"
                    ></crv-select-single> -->
                </div>
            </slot>
            <div
                v-if="!!$slots.default"
                class="more-menu_custom"
            >
                <slot />
            </div>
            <div class="more-menu_items">
                <crv-app-nav-item
                    v-for="(item) in moreMenu"
                    :key="item.key"
                    class="more-menu_item"
                    should-show-chevron
                    :item="item"
                    @click="handleClickMenuItem(item)"
                >
                    {{ item.label }}
                </crv-app-nav-item>
            </div>
        </div>
    </v-navigation-drawer>
</template>


<script>
import truncate from 'lodash/truncate'
import { mdiDotsHorizontal, mdiLogout } from '@mdi/js'
import CrvAppNavItem from '@/components/CrvAppNavItem.vue'


export default {
    name: 'CrvAppBottomMenu',

    components: {
        CrvAppNavItem,
    },

    props: {
        accountName: {
            type: String,
            default: undefined,
        },

        accountLabel: {
            type: String,
            default: 'Ingelogd als',
        },

        customerNumberLabel: {
            type: String,
            default: '',
        },

        customerNumber: {
            type: String,
            default: '',
        },

        allowSwitchCustomer: {
            type: Boolean,
            default: false,
        },

        customerName: {
            type: String,
            default: undefined,
        },

        locations: {
            type: Array,
            default: () => [],
        },

        locationsLabel: {
            type: String,
            default: 'Toon gegevens van',
        },

        logoutLabel: {
            type: String,
            default: 'Uitloggen',
        },

        bottomMenu: {
            type: Array,
            default: () => [],
        },

        moreMenu: {
            type: Array,
            default: () => [],
        },

        moreMenuLabel: {
            type: String,
            default: 'Meer',
        }, 

        activeMenuId: {
            type: String,
            default: '',
            note: 'The id (key) of the active menu item',
        },
    },

    emits: [
        'navigate',
        'logout',
        'switch-customer',
        'click-location',       
    ],

    static() {
        return {
            mdiDotsHorizontal,
            mdiLogout,
        }
    },

    data() {
        return {
            showBottomMenu: true,
            showMoreMenu: false,
            moreMenuHeight: undefined,
        }
    },

    computed: {
        hasSingleLocation() {
            return this.locations && this.locations.length === 1
        },

        singleLocationName() {
            return this.locations[0].label
        },

        hasMultipleLocations() {
            return this.locations && this.locations.length > 1
        },

        truncateAccountName() {
            return truncate( 
                this.accountName, { 
                    length: 33, 
                    omission: '...'
                } 
            ) 
        }
    },

    mounted() {
        this.moreMenuHeight = this.moreMenuContentHeight()
    },

    methods: {
        handleClickMenuItem(item) {
            this.$emit('navigate', item)
        },

        handleClickMore() {
            this.showMoreMenu = !this.showMoreMenu
        },

        handleClickLogout() {
            this.$emit('logout')
        },

        handleClickCustomer() {
            if (this.allowSwitchCustomer) {
                this.$emit('switch-customer')
            }
        },

        getActive(key) {
            return key === this.activeMenuId
        },

        moreMenuContentHeight() {
            return this.$refs.moreMenuContent.clientHeight
        }
    },
}
</script>


<style lang='scss' scoped>
@import '@/assets/variables.scss';

$header-bg-color: $base-gray-5;
$header-text-color: $base-gray-90;
$header-icon-color: $base-gray-90;

$body-background-color: $base-gray-5;
$body-text-color: $base-gray-80;
$body-icon-color: $base-gray-70;
$body-link-icon-color: $base-gray-50;

$border-color: $base-gray-40;
$location-select-border-color: $base-gray-90;

$header-top-radius: 12px;

.more-menu {
    :deep(.v-navigation-drawer__content) {
        overflow-y: hidden;
    }

    &_header {
        border-radius: $header-top-radius $header-top-radius 0 0;
        background-color: $header-bg-color;
        color: $header-text-color;
        display: flex;
        padding: $vertical-spacing-single $horizontal-spacing-single;

        .crv-svg-icon {
            fill: $header-icon-color
        }
    }

    &_account {
        flex: 2;
        overflow: hidden;
    }

    &_account-label {
        font-style: $font-style-emphasis;
        font-size: $font-size-xs;
    }

    &_account-name {
        font-size: $font-size-m;
        font-weight: $font-weight-strong;
        margin-bottom: $vertical-spacing-half;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &_customer-number,
    &_location-name {
        font-size: $font-size-s;
    }

    &_location-name {
        margin-top: $vertical-spacing-half;
    }

    &_customer-number-label::after {
        content: "\00a0";
    }

    &_logout {
        font-size: $font-size-s;
        display: flex;
        align-items: center;
        cursor: pointer;

        .crv-svg-icon {
            margin-left: $horizontal-spacing-half;
        }
    }

    &_item {
        height: $element-height-4;
        background-color: $body-background-color;
        color: $body-text-color;
        border-top: $border-single solid $border-color;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 $horizontal-spacing-single;

        :deep(.crv-app-nav-item) {
            cursor: pointer;
        }

        :deep(.crv-app-nav-item_icon) {
            fill: $body-icon-color;
            width: 1rem;
            height: 1rem;
            margin-right: $horizontal-spacing-single;
        }

        :deep(.crv-app-nav-item_label) {
            flex: 1;
        }

        :deep(.crv-app-nav-item_link-icon) {
            fill: $body-link-icon-color;
        }
    }

    &_locations {
        background-color: $body-background-color;
        color: $body-text-color;
        border-top: $border-single solid $border-color;
        padding: 0 $horizontal-spacing-single $vertical-spacing-single $horizontal-spacing-single;

        :deep(.crv-form-block) {
            margin-bottom: 0;

            .crv-form-block_heading {
                display: none;
            }

            .crv-form-block_content {
                /* TODO nasty trick. crv-common has unnecessary deep selector */
                div.display {
                    background-color: $header-bg-color;
                    color: $header-text-color;
                    border-bottom-color: $header-text-color;
                    font-size: $font-size-m;

                    .crv-svg-icon {
                        fill: $header-text-color;
                        margin-right: -2px;
                    }
                }
            }
        }
    }

    /* iPhone-X */
    @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        .more-menu_content {
            bottom: $element-height-8;
        }
    }
}

</style>
