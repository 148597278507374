const BELGIUM = {
    alphaTwoCode: 'BE',
}

const CHINA = {
    alphaTwoCode: 'CN',
}

const CZECH_REPUBLIC = {
    alphaTwoCode: 'CZ',
}

const GERMANY = {
    alphaTwoCode: 'DE',
}

const LUXEMBOURG = {
    alphaTwoCode: 'DE',
}

const NETHERLANDS = {
    alphaTwoCode: 'NL',
    numericCode: 528,
}

const RUSSIA = {
    alphaTwoCode: 'RU',
}

const SPAIN = {
    alphaTwoCode: 'ES',
}

const UK = {
    alphaTwoCode: 'GB',
}

const USA = {
    alphaTwoCode: 'US',
}

export default {
    BELGIUM,
    CHINA,
    CZECH_REPUBLIC,
    GERMANY,
    LUXEMBOURG,
    NETHERLANDS,
    RUSSIA,
    SPAIN,
    UK,
    USA,
}
