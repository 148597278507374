<template>
    <v-footer class="crv-app-footer d-flex justify-center">
        <logo-with-tagline
            style="width: 6em; height: 6em;"
        />
    </v-footer>
</template>

<script>
import { logoWithTagline } from '@/images/icons'

export default {
    name: 'CrvAppFooter',

    components: {
        logoWithTagline
    },
}
</script>


<style lang="scss">
@import '@/assets/variables.scss';

$logo-width: 91px;
$logo-height: 34px;

.crv-app-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: $desktop-footer-height !important;
    margin-top: $vertical-spacing-quadruple;
    padding: 0 $horizontal-spacing-quadruple;

    // .logo-width {
    //     width: $logo-width;
    //     height: $logo-height;
    //     color: tomato;
    // }
    // not certain why, setting custom classes does not seem to affect the logo.
}
</style>