<template>
    <v-hover 
        v-slot="{ isHovering }"
    >
        <v-sheet
            v-ripple.center
            class="d-flex flex-row align-center is-clickable rounded-sm"
            :color="getColor(isHovering)"
        >
            <div>
                <div class="text-right">
                    {{ name }}
                </div>
                <div class="text-right v-list-item-subtitle">
                    {{ formattedCustomerName }}
                </div>
            </div>
            <v-icon :icon="menuDown" />
            <v-tooltip
                v-model="showTooltip"
                activator="parent"
                location="bottom"
                offset="14" 
            >
                {{ tooltipText }}
            </v-tooltip>
            <v-menu
                v-model="showMenu"
                activator="parent"
                offset="18"
            >
                <crv-app-account-menu-content
                    :name="name"
                    :label="accountLabel"
                    :is-switch-customer-page="isSwitchCustomerPage"
                    :allow-switch-customer="allowSwitchCustomer"
                    :customer-name="customerName"
                    :locations="locations"
                    :locations-label="locationsLabel"
                    :logout-label="logoutLabel"
                    :my-crv-label="myCrvLabel"
                    :faq-label="faqLabel"
                    :market-country-code="marketCountryCode"
                    :is-my-crv="isMyCrv"
                    :select-customer-text="selectCustomerText"
                    :show-my-crv-and-faq="showMyCrvAndFaq"
                    @logout="handleLogout"
                    @close="handleCloseAccountMenu"
                    @switch-customer="handleSwitchCustomer"
                    @click-location="handleClickLocation"
                />
            </v-menu>
        </v-sheet>
    </v-hover>
</template>


<script>
import CrvAppAccountMenuContent from '@/components/CrvAppAccountMenuContent.vue'
import { mdiMenuDown } from '@mdi/js'
import storageHelper from '@/security/storageHelper'


export default {
    name: 'CrvAppAccountMenu',

    components: {
        CrvAppAccountMenuContent,
    },

    props: {
        name: {
            type: String,
            default: undefined,
        },

        tooltipText: {
            type: String,
            default: '',
        },

        allowSwitchCustomer: {
            type: Boolean,
            default: true,
        },

        accountLabel: {
            type: String,
            default: '',
        },

        customerName: {
            type: String,
            default: undefined,
        },

        customerNumber: {
            type: String,
            default: '',
        },

        selectCustomerText: {
            type: String,
            default: 'Selecteer bedrijf',
        },

        isSwitchCustomerPage: {
            type: Boolean,
            default: false,
        },

        locations: {
            type: Array,
            default: () => [],
        },

        locationNumber: {
            type: String,
            default: '',
        },

        locationsLabel: {
            type: String,
            default: 'Toon gegevens van',
        },

        myCrvLabel: {
            type: String,
            default: 'Mijn CRV',
        },

        faqLabel: {
            type: String,
            default: 'Veelgestelde vragen',
        },

        logoutLabel: {
            type: String,
            default: 'Uitloggen',
        },

        marketCountryCode: {
            type: String,
            required: true,
        },

        showMyCrvAndFaq: {
            type: Boolean,
            default: true,
        },

        isMyCrv: {
            type: Boolean,
            default: false,
        },
    },

    emits: [
        'logout', 
        'switch-customer', 
        'click-location'
    ],

    data() {
        return {
            showMenu: false,
            showTooltip: false,
            menuDown: mdiMenuDown,
        }
    },

    computed: {
        formattedCustomerName() {
            if (this.isSwitchCustomerPage) {
                const authorizedCustomers = storageHelper.loadAuthorizedCustomers()
                const customerNumber = storageHelper.loadCustomerNumber()
                if (authorizedCustomers?.includes(customerNumber)) {
                    const name = storageHelper.loadCustomerName()
                    const location = storageHelper.loadLocationNumber()
                    return this.deriveFormattedCustomerName(location, name)
                }
                return this.selectCustomerText
            }
            return this.deriveFormattedCustomerName(this.locationNumber, this.customerName)
        },
    },

    watch: {
        showMenu() {
            if (this.showMenu) {
                this.showTooltip = false
            }
        },
    },

    methods: {
        getColor(isHovering) {
            return isHovering ? 'white' : 'base-brand-blue'            
        },

        deriveFormattedCustomerName(location, customerName) {
            const formattedCustomerName = `${this.customerNumber} ${customerName}`
            if (location) {
                return `${formattedCustomerName}, ${location}`
            }
            return formattedCustomerName
        },

        handleLogout() {
            this.$emit('logout')
        },

        handleSwitchCustomer() {
            this.handleCloseAccountMenu()
            this.$emit('switch-customer')
        },

        handleClickLocation(location) {
            this.$emit('click-location', location)
        },

        handleCloseAccountMenu() {
            this.showMenu = false
        },
    },
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';
    .is-clickable {
        cursor: pointer;
    }

</style>