<template>
 <!-- eslint-disable -->
<svg class="slogan-crv crv-svg-icon"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 11">
    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
      <g>
        <path id="Fill-2" fill="#004685" d="M95.108 10.221c2.643 0 3.919-1.352 3.919-3.14 0-3.657-5.621-2.614-5.621-4.17 0-.588.499-.986 1.394-.986.999 0 2.054.34 2.847 1.074l1.16-1.527C97.839.577 96.548.106 94.99.106c-2.304 0-3.713 1.352-3.713 2.98 0 3.686 5.636 2.495 5.636 4.23 0 .557-.557 1.086-1.703 1.086A4.294 4.294 0 0 1 92.1 7.08l-1.13 1.585c.91.924 2.26 1.556 4.139 1.556zm-7.252-.177l2.79-9.791h-2.35L86.595 7.33 84.744.253h-1.63L81.25 7.33 79.533.253h-2.335l2.805 9.791h2.217l1.702-6.723 1.717 6.723h2.217zM71.854 8.37c-1.804 0-2.964-1.395-2.964-3.214 0-1.835 1.16-3.214 2.964-3.214 1.807 0 2.982 1.379 2.982 3.214 0 1.82-1.175 3.214-2.982 3.214zm0 1.851c2.966 0 5.124-2.113 5.124-5.065 0-2.95-2.158-5.064-5.124-5.064-2.95 0-5.107 2.115-5.107 5.064 0 2.952 2.157 5.065 5.107 5.065zm-9.98 0c2.157 0 3.39-1.188 4.095-2.408l-1.792-.865c-.41.807-1.29 1.422-2.304 1.422-1.777 0-3.069-1.364-3.069-3.214 0-1.848 1.292-3.214 3.07-3.214 1.013 0 1.893.631 2.303 1.424l1.792-.88C65.279 1.265 64.03.092 61.873.092c-2.936 0-5.21 2.056-5.21 5.064 0 3.01 2.274 5.065 5.21 5.065zM48.206 4.73h-2.188V2.088h2.188c.836 0 1.467.498 1.467 1.322 0 .82-.63 1.32-1.467 1.32zm3.654 5.314l-2.2-3.727c1.058-.251 2.144-1.176 2.144-2.907 0-1.821-1.247-3.157-3.288-3.157h-4.58v9.791h2.084V6.567h1.528l1.923 3.477h2.391zm-9.556 0V8.211h-4.843V5.993h4.74V4.159h-4.74v-2.07h4.843V.252h-6.928v9.791h6.928zm-11.111 0V2.088h2.847V.253h-7.81v1.835h2.863v7.956h2.1zm-8.545 0V2.088h2.849V.253h-7.81v1.835h2.863v7.956h2.098zm-5.988 0V8.211h-4.844V5.993h4.74V4.159h-4.74v-2.07h4.844V.252H9.73v9.791h6.93zM4.771 8.24H2.083V5.963H4.77c.822 0 1.262.529 1.262 1.147 0 .703-.469 1.13-1.262 1.13zm-.074-4.068H2.084V2.043h2.613c.704 0 1.145.457 1.145 1.073 0 .616-.441 1.056-1.145 1.056zm.616 5.872c1.865 0 2.847-1.175 2.847-2.642 0-1.233-.836-2.246-1.879-2.407.926-.19 1.69-1.013 1.69-2.245 0-1.308-.955-2.497-2.82-2.497H0v9.791h5.313z"/>
        <polygon id="Fill-1" fill="#98002E" points="102.889 .239 107.797 5.145 102.889 10.048 105.944 10.048 110.847 5.145 105.944 .239"/>
        <path id="Fill-5" fill="#004685" d="M199.172 10.044V8.21h-4.844V5.994h4.74V4.159h-4.74v-2.07h4.844V.254h-6.93v9.791h6.93zm-13.228 0v-4.05h4.742V4.159h-4.742v-2.07h4.846V.254h-6.93v9.791h2.084zm-6.105 0h2.085V.253h-2.085v9.791zm-1.454 0V8.21h-4.14V.253h-2.083v9.791h6.223zm-15.149-5.313h-2.187V2.09h2.187c.837 0 1.47.499 1.47 1.32 0 .823-.633 1.321-1.47 1.321zm3.656 5.313l-2.203-3.729c1.056-.248 2.143-1.172 2.143-2.905 0-1.821-1.246-3.157-3.288-3.157h-4.58v9.791h2.085v-3.48h1.527l1.923 3.48h2.393zm-9.557 0V8.21h-4.845V5.994h4.74V4.159h-4.74v-2.07h4.845V.254h-6.929v9.791h6.929zm-11.113 0V2.09h2.848V.253h-7.808V2.09h2.862v7.954h2.098zm-8.543 0V2.09h2.848V.253h-7.811V2.09h2.863v7.954h2.1zm-5.99 0V8.21h-4.843V5.994h4.743V4.159h-4.743v-2.07h4.843V.254h-6.928v9.791h6.928zM119.8 8.24h-2.684V5.963h2.685c.82 0 1.263.529 1.263 1.145 0 .707-.47 1.132-1.263 1.132zm-.072-4.067h-2.612V2.044h2.612c.702 0 1.144.455 1.144 1.072 0 .618-.442 1.057-1.144 1.057zm.616 5.871c1.864 0 2.847-1.174 2.847-2.641 0-1.232-.837-2.247-1.878-2.408.925-.191 1.688-1.012 1.688-2.247 0-1.305-.955-2.495-2.82-2.495h-5.15v9.791h5.313z"/>
      </g>
    </g>
  </svg>
<!-- eslint-enable -->
</template>