<template>
 <!-- eslint-disable -->
<svg class="doctors-case crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 385">
    <g fill="none" fill-rule="evenodd" transform="translate(0 104)">
        <ellipse cx="256" cy="231" fill="#F7F6F6" rx="256" ry="50"/>
        <g transform="translate(75)">
            <path fill="#F1F0EF"
                  d="M28.3435239,28.6772425 L240.578366,67.7324992 C251.959026,69.8267576 260.22479,79.7436176 260.234827,91.3153608 L260.359732,235.318937 C260.371228,248.573765 249.635388,259.328246 236.38056,259.339742 C234.744584,259.341161 233.112749,259.175302 231.510514,258.84475 L19.1507681,215.033595 C7.99987007,212.733094 -5.71107767e-15,202.914328 0,191.528598 L0,52.2809209 C-8.72867617e-15,39.0260885 10.7451647,28.2809238 23.9999971,28.2809238 C25.4568673,28.2809238 26.9107115,28.4135777 28.3435239,28.6772425 Z"/>
            <path fill="#EAE8E5"
                  d="M247.258517,255.718373 L247.056373,255.825729 C244.154419,257.323257 240.814301,258.399882 237.03602,259.055604 L237.03602,259.055604 L247.258517,255.718373 Z M139.954488,0.376746018 L335.770423,36.8692886 L335.609517,36.915373 L335.924846,36.9661411 C343.971016,38.3030844 350.36593,41.4631324 355.10959,46.446285 C359.971841,51.5540164 362.179728,57.837923 361.733253,65.2980048 L360.651467,197.934103 C361.492747,204.564962 359.551461,210.789323 354.82761,216.607185 C350.103758,222.425048 345.313141,225.321219 340.455759,225.295699 L247.258517,255.718373 L247.510935,255.585779 C250.066154,254.206244 252.270599,252.489753 254.124271,250.436307 C258.158734,245.967042 260.189812,241.083107 260.217507,235.784501 L260.217507,92.2660563 C260.037546,85.3427998 258.006467,79.646539 254.124271,75.177274 C250.242075,70.7080091 243.480531,67.8349102 233.839641,66.5579773 L234.013517,66.506373 L24.9655172,28.2499919 L107.543815,3.59459459 C113.362287,1.70052622 119.068433,0.62791003 124.662252,0.376746018 C130.256071,0.125582006 135.353483,0.125582006 139.954488,0.376746018 Z"/>
            <g transform="translate(77.914 94.658)">
                <path fill="#DB3E3E"
                      d="M46.1490066,93.0955225 C71.6363992,98.2060793 92.2980132,81.716818 92.2980132,56.2657265 C92.2980132,30.8146351 71.6363992,6.03952412 46.1490066,0.928967335 C20.661614,-4.18158946 0,12.3076718 0,37.7587633 C0,63.2098547 20.661614,87.9849657 46.1490066,93.0955225 Z"/>
                <polygon fill="#FFF"
                         points="57.536 38.361 81.51 43.173 81.51 67.137 57.536 62.325 57.536 86.289 33.563 81.477 33.563 57.514 9.589 52.702 9.589 28.738 33.563 33.55 33.563 9.586 57.536 14.398"/>
            </g>
            <path fill="#E4E0DE"
                  d="M63.5298013,16.7747748 L255.421321,50.3243243 C275.446795,53.7151501 287.316613,56.9103453 291.030776,59.9099099 C294.744938,62.9094746 296.570119,67.466907 296.506316,73.5822072 L296.071494,224.063063 C296.490013,226.869093 295.690896,230.064288 293.674143,233.648649 C291.65739,237.233009 288.860932,240.428204 285.284768,243.234234 L293.590122,240.837838 C296.082323,238.908973 298.108122,236.512577 299.667521,233.648649 C301.226919,230.78472 302.24572,227.589525 302.723924,224.063063 L303.338215,73.0900901 C304.128613,65.9009009 301.680774,60.3093093 295.9947,56.3153153 C290.308626,52.3213213 278.265463,49.1261261 259.865213,46.7297297 L75.5165563,13.1801802 L63.5298013,16.7747748 Z"/>
            <g transform="translate(123.464 3.595)">
                <path fill="#928782"
                      d="M1.42053421,18.4611278 L22.9263911,22.7213881 C37.2553856,12.3074185 50.6291137,8.04715827 63.0475755,9.94060727 C75.4660374,11.8340563 85.5665737,20.3545768 93.3491844,35.5021688 L108.77593,38.3423423 L124.662252,34.0820821 L124.662252,29.8218218 L108.77593,34.0820821 L96.0042628,31.2419086 C86.4515998,16.0943165 75.4660374,7.57379602 63.0475755,5.68034701 C50.6291137,3.78689801 37.2553856,8.04715827 22.9263911,18.4611278 L1.42053421,14.2008675 L1.42053421,18.4611278 Z"/>
                <path fill="#A29894"
                      d="M1.42053421,14.2008675 L22.9263911,18.4611278 C36.3001193,8.99388277 49.1962143,4.73362251 61.6146761,5.68034701 C74.033138,6.62707152 85.4963335,15.147592 96.0042628,31.2419086 L108.77593,34.0820821 L124.662252,29.8218218 L108.77593,26.9816483 C98.3509527,11.8340563 86.4515998,3.31353576 73.0778717,1.42008675 C59.7041435,-0.473362251 46.3304154,2.84017351 32.9566872,11.360694 L18.6276928,8.52052052 L1.42053421,14.2008675 Z"/>
            </g>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>