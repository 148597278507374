<template>
 <!-- eslint-disable -->
<svg class="cross-road crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 385">
    <defs>
        <ellipse id="crossroad-a" cx="256" cy="294" rx="256" ry="50"/>
        <linearGradient id="crossroad-b" x1="0%" x2="100%" y1="94.168%" y2="94.168%">
            <stop offset="0%" stop-color="#5D5551"/>
            <stop offset="100%" stop-color="#7F746F"/>
        </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(0 41)">
        <use fill="#F7F6F6" xlink:href="#crossroad-a"/>
        <g transform="translate(126)">
            <path fill="url(#crossroad-b)"
                  d="M5.20000029,1.3640553 C8.60899864,0.469812605 12.1910025,0.469812605 15.6000009,1.3640553 C18.6637453,2.16773156 20.8000011,4.93664267 20.8000011,8.10404306 L20.8000011,292.264052 L20.8000011,292.264052 C18.4888899,294.754684 15.022223,296 10.4000006,296 C5.7777781,296 2.31111124,294.754684 0,292.264052 L0,8.10404306 C5.00283744e-16,4.93664267 2.13625589,2.16773156 5.20000029,1.3640553 Z"
                  transform="translate(120 4)"/>
            <g transform="translate(0 55)">
                <polygon fill="#D2CCC8" points="21.221 17.297 207.631 24.063 215.878 76.125 209.007 79.846 14.35 21.018"
                         transform="rotate(10 115.114 48.572)"/>
                <polygon fill="#E4E0DE" points="14.159 19.482 200.568 26.248 208.816 78.31 21.845 67.636 3.67 38.904"
                         transform="rotate(10 106.243 48.896)"/>
                <rect width="109.824" height="8.58" x="84.58" y="41.979" fill="#928782" rx="4.29"
                      transform="scale(-1 1) rotate(-13 0 1270.574)"/>
                <rect width="49.764" height="8.58" x="142.87" y="65.176" fill="#928782" rx="4.29"
                      transform="scale(-1 1) rotate(-13 0 1541.81)"/>
            </g>
            <g transform="translate(27)">
                <g transform="matrix(-1 0 0 1 222.86 0)">
                    <polygon fill="#99BDDE"
                             points="22.937 17.297 209.347 24.063 217.594 76.125 210.723 79.846 16.066 21.018"
                             transform="rotate(10 116.83 48.572)"/>
                    <polygon fill="#75A3CD"
                             points="15.886 19.351 202.296 26.117 210.543 78.179 23.572 67.504 3.884 40.919"
                             transform="rotate(10 107.213 48.765)"/>
                </g>
                <rect width="123.552" height="8.58" x="31.712" y="38.675" fill="#FFF" rx="4.29"
                      transform="rotate(-13 93.488 42.965)"/>
                <rect width="56.628" height="8.58" x="32.24" y="62.646" fill="#FFF" rx="4.29"
                      transform="rotate(-13 60.554 66.936)"/>
            </g>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>