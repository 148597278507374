<template>
 <!-- eslint-disable -->
<svg class="search-crv crv-svg-icon"  id="Layer_4"
            data-name="Layer 4"
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            viewBox="0 0 512 512"
        >
            <path d="M512,460a23.2102,23.2102,0,0,1-7,17l-28,28a24.14286,24.14286,0,0,1-34,0L343,405a23.09974,23.09974,0,0,1-7-17V372q-56.01562,44.01563-128,44-57,0-104.5-28A209.905,209.905,0,0,1,28,312.5Q-.00781,265.01563,0,208q0-57,28-104.5A210.149,210.149,0,0,1,103.5,28Q150.98438.01563,208,0q57,0,104.5,28A210.39356,210.39356,0,0,1,388,103.5q27.98438,47.50781,28,104.5,0,72-44,128h16a23.2102,23.2102,0,0,1,17,7L505,443A23.18511,23.18511,0,0,1,512,460ZM272.5,319A124.60015,124.60015,0,0,0,319,272.5a130.86029,130.86029,0,0,0,0-129A124.82607,124.82607,0,0,0,272.5,97a130.86029,130.86029,0,0,0-129,0A124.60015,124.60015,0,0,0,97,143.5,126.84361,126.84361,0,0,0,80,208a126.76116,126.76116,0,0,0,17,64.5A124.3751,124.3751,0,0,0,143.5,319,126.76116,126.76116,0,0,0,208,336,126.84361,126.84361,0,0,0,272.5,319Z" />
</svg>
<!-- eslint-enable -->
</template>