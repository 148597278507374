<template>
 <!-- eslint-disable -->
<svg class="tachometer-alt-fast-solid crv-svg-icon"  width="20px" height="16px" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>tachometerAltFastSolid</title>
        <g id="System-icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-/-__overrides-/-_System-icons-/-Solid-/-tachometerAltFastSolid-➖" transform="translate(-2.000000, -4.000000)" fill="#7F746F" fill-rule="nonzero">
                <path d="M12,4 C6.47708333,4 2,8.605 2,14.2857143 C2,16.1714286 2.49479167,17.9378571 3.35625,19.4571429 C3.55104167,19.8007143 3.92222222,20 4.30902778,20 L19.6909722,20 C20.0777778,20 20.4489583,19.8007143 20.64375,19.4571429 C21.5052083,17.9378571 22,16.1714286 22,14.2857143 C22,8.605 17.5229167,4 12,4 Z M12,6.28571429 C12.6135417,6.28571429 13.1111111,6.7975 13.1111111,7.42857143 C13.1111111,8.05964286 12.6135417,8.57142857 12,8.57142857 C11.3864583,8.57142857 10.8888889,8.05964286 10.8888889,7.42857143 C10.8888889,6.7975 11.3864583,6.28571429 12,6.28571429 Z M5.33333333,16.5714286 C4.71979167,16.5714286 4.22222222,16.0596429 4.22222222,15.4285714 C4.22222222,14.7975 4.71979167,14.2857143 5.33333333,14.2857143 C5.946875,14.2857143 6.44444444,14.7975 6.44444444,15.4285714 C6.44444444,16.0596429 5.946875,16.5714286 5.33333333,16.5714286 Z M7,10.8571429 C6.38645833,10.8571429 5.88888889,10.3453571 5.88888889,9.71428571 C5.88888889,9.08321429 6.38645833,8.57142857 7,8.57142857 C7.61354167,8.57142857 8.11111111,9.08321429 8.11111111,9.71428571 C8.11111111,10.3453571 7.61354167,10.8571429 7,10.8571429 Z M13.9138889,17.7142857 L10.0861111,17.7142857 C9.89513889,17.3767857 9.77777778,16.99 9.77777778,16.5714286 C9.77777778,15.3089286 10.7725694,14.2857143 12,14.2857143 C12.1961806,14.2857143 12.3826389,14.32 12.5638889,14.3692857 L16.3333333,9.19964286 C16.6104167,8.8225 17.1319444,8.74535714 17.5003472,9.02821429 C17.8680556,9.31214286 17.9430556,9.84964286 17.6670139,10.2285714 L13.8975694,15.3978571 C14.0993056,15.7417857 14.2222222,16.1403571 14.2222222,16.5714286 C14.2222222,16.99 14.1048611,17.3767857 13.9138889,17.7142857 Z M18.6666667,16.5714286 C18.053125,16.5714286 17.5555556,16.0596429 17.5555556,15.4285714 C17.5555556,14.7975 18.053125,14.2857143 18.6666667,14.2857143 C19.2802083,14.2857143 19.7777778,14.7975 19.7777778,15.4285714 C19.7777778,16.0596429 19.2802083,16.5714286 18.6666667,16.5714286 Z" id="tachometerAltFastSolid"></path>
            </g>
        </g>
    </svg>
<!-- eslint-enable -->
</template>