<template>
 <!-- eslint-disable -->
<svg class="medicine crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 385">
    <defs>
        <linearGradient id="medicine-a" x1="54.537%" x2="0%" y1="54.097%" y2="54.097%">
            <stop offset="0%" stop-color="#D9D5D3"/>
            <stop offset="100%" stop-color="#BAB0AC"/>
        </linearGradient>
        <linearGradient id="medicine-b" x1="0%" x2="100%" y1="54.728%" y2="54.728%">
            <stop offset="0%" stop-color="#D2CCC8"/>
            <stop offset="100%" stop-color="#E4E0DE"/>
        </linearGradient>
        <linearGradient id="medicine-c" x1="43.793%" x2="43.793%" y1="50%" y2="81.221%">
            <stop offset="0%" stop-color="#D9D5D3"/>
            <stop offset="100%" stop-color="#BAB0AC"/>
        </linearGradient>
        <linearGradient id="medicine-d" x1="50%" x2="50%" y1="50%" y2="100%">
            <stop offset="0%" stop-color="#74ACD1"/>
            <stop offset="100%" stop-color="#4074A4"/>
        </linearGradient>
        <linearGradient id="medicine-e" x1="44.037%" x2="40.398%" y1="52.64%" y2="65.961%">
            <stop offset="0%" stop-color="#D9D5D3"/>
            <stop offset="100%" stop-color="#BAB0AC"/>
        </linearGradient>
        <linearGradient id="medicine-f" x1="55.058%" x2="50%" y1="47.634%" y2="69.084%">
            <stop offset="0%" stop-color="#74ACD1"/>
            <stop offset="100%" stop-color="#4074A4"/>
        </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <rect width="512" height="385" fill="#FFF"/>
        <g transform="translate(0 70)">
            <ellipse cx="256" cy="265" fill="#F7F6F6" rx="256" ry="50"/>
            <g transform="translate(33)">
                <g transform="translate(210 34)">
                    <polygon fill="#D2CCC8" points="0 16.939 94.9 32.576 94.9 213.697 0 186.28"/>
                    <polygon fill="#E4E0DE" points="94.9 32.576 169 9.153 169 190.034 94.9 213.697"/>
                    <polygon fill="#EAE8E5" points="80.6 .049 169 9.121 94.9 32.576 0 16.939"/>
                    <polygon fill="#F7F6F6" points="13 79.485 78 92.515 78 165.485 13 152.455"/>
                </g>
                <g transform="translate(60)">
                    <path fill="url(#medicine-a)"
                          d="M78,33.8412698 C121.07821,33.8412698 156,51.0804779 156,64.3791441 C156,64.6135498 155.98915,64.8471733 155.967598,65.0799693 L156,65.0793651 L156,222.571429 L155.972033,222.571783 C154.854279,235.569431 120.3729,246 78,246 C35.6271003,246 1.14572115,235.569431 0.0279668157,222.571783 L0,222.571429 L0,65.0793651 L0.0324024696,65.0799693 C0.0108496809,64.8471733 0,64.6135498 0,64.3791441 C0,51.0804779 34.9217895,33.8412698 78,33.8412698 Z"/>
                    <path fill="url(#medicine-b)"
                          d="M78.65,14.3174603 C107.973594,14.3174603 132.084273,19.4545633 134.920914,26.0320494 L135.2,26.031746 L135.2,44.3249683 C116.93299,51.2194339 98.2773196,54.6666667 79.2329897,54.6666667 C60.1886598,54.6666667 41.1443299,51.2194339 22.1,44.3249683 L22.1,26.031746 L22.3790862,26.0320494 C25.2157274,19.4545633 49.3264057,14.3174603 78.65,14.3174603 Z"/>
                    <path fill="#BCB4B1"
                          d="M78.65,6.50793651 C97.0768149,6.50793651 113.491407,8.25249453 124.085455,10.9704479 L136.5,10.9705215 L136.5,18.7800454 L136.43553,18.7802007 C134.997287,24.9907262 109.675946,29.9365079 78.65,29.9365079 C47.6240535,29.9365079 22.3027129,24.9907262 20.8644704,18.7802007 L20.8,18.7800454 L20.8,10.9705215 L33.2145455,10.9704479 C43.8085932,8.25249453 60.2231851,6.50793651 78.65,6.50793651 Z"/>
                    <ellipse cx="78.65" cy="11.714" fill="#E4E0DE" rx="57.85" ry="11.714"/>
                    <path fill="#F7F6F6"
                          d="M27.3,101.52381 C45.9482759,106.824457 62.8482759,109.34831 78,109.09537 C93.1517241,108.84243 110.051724,106.318576 128.7,101.52381 L128.7,203.285582 C110.051724,208.333289 93.1517241,210.857143 78,210.857143 C62.8482759,210.857143 45.9482759,208.333289 27.3,203.285582 L27.3,101.52381 Z"/>
                </g>
                <g transform="rotate(3 -4314.406 146.977)">
                    <path fill="url(#medicine-c)"
                          d="M85.246601,0.677764701 C93.3381654,0.584090912 100.296198,7.89180457 100.787801,17 C101.279405,26.1081954 94.9861689,33.4159091 86.7314658,33.3222353 L50.966717,32.9163794 L50.1886756,1.08362055 L85.246601,0.677764701 Z"/>
                    <path fill="url(#medicine-d)"
                          d="M50.1886756,1.08362055 C51.8444722,5.19672784 52.8505243,10.6526568 53.2068317,17.4514073 C53.5631391,24.2501579 52.8164342,29.4051486 50.966717,32.9163794 L15.2019682,32.5105236 C6.94726512,32.4168498 0.305688389,25.4725519 0.367162186,17 C0.42949445,8.52744814 7.03918573,1.58315019 15.1307501,1.4894764 L50.1886756,1.08362055 Z"/>
                </g>
                <g transform="rotate(3 -4663.398 1413.091)">
                    <path fill="url(#medicine-e)"
                          d="M87.2088854,4.14289181 C95.3004497,4.09119183 102.258482,11.4367991 102.750086,20.5470296 C103.241689,29.6544171 96.9484533,36.994279 88.6937502,36.9434642 L52.9290014,36.7162168 L52.1509599,4.37391106 L87.2088854,4.14289181 Z"
                          transform="rotate(-9 77.464 20.543)"/>
                    <path fill="url(#medicine-f)"
                          d="M52.7540207,12.0327528 C54.2081488,14.2118941 55.0378134,19.5664545 55.2430145,28.096434 C55.4482157,36.6264136 54.8778982,42.0526218 53.5320621,44.3750586 L17.7673134,44.1388601 C9.51261023,44.0830352 2.8710335,36.9492592 2.9325073,28.2058713 C2.99483956,19.4628165 9.60453084,12.3291573 17.6960952,12.2725318 L52.7540207,12.0327528 Z"
                          transform="rotate(-9 29.108 28.204)"/>
                </g>
                <g transform="translate(263 256)">
                    <path fill="#EAE8E5"
                          d="M58,9.13043478 L58,18.8695652 L57.9851852,18.8696886 C57.4911432,23.9394623 44.7031194,28 29,28 C13.2968806,28 0.508856801,23.9394623 0.0148148381,18.8696886 L0,18.8695652 L0,9.13043478 L58,9.13043478 Z"/>
                    <path fill="#E4E0DE"
                          d="M15.7317526,22.0556208 C16.5024799,22.2627873 17.8358819,22.4181601 19.7319588,22.5217391 C21.6280356,22.6253182 23.138826,22.6253182 24.2643299,22.5217391 L24.2643299,18.7417391 L27.4141385,18.8435681 L41.8556701,3.65217391 L33.7296907,3.65217391 L15.7317526,22.0556208 Z"/>
                    <path fill="#D2CCC8"
                          d="M42.2704127,1.04354775 C51.6121021,2.61040269 58,5.77989531 58,9.43478261 C58,14.6454692 45.0162577,18.8695652 29,18.8695652 C27.3873138,18.8695652 25.8053731,18.8267388 24.2643366,18.7443911 Z M29,0 C30.6131407,0 32.1955187,0.0428505303 33.7369665,0.125243754 L15.7292424,17.8259596 C6.38774068,16.2590845 0,13.0896249 0,9.43478261 C0,4.22409606 12.9837423,0 29,0 Z"/>
                    <polygon fill="#BCB4B1" points="33.73 .128 33.73 3.652 15.732 22.056 15.732 17.817"/>
                </g>
                <g transform="translate(321 238)">
                    <path fill="#EAE8E5"
                          d="M58,9.13043478 L58,18.8695652 L57.9851852,18.8696886 C57.4911432,23.9394623 44.7031194,28 29,28 C13.2968806,28 0.508856801,23.9394623 0.0148148381,18.8696886 L0,18.8695652 L0,9.13043478 L58,9.13043478 Z"/>
                    <path fill="#E4E0DE"
                          d="M15.7317526,22.0556208 C16.5024799,22.2627873 17.8358819,22.4181601 19.7319588,22.5217391 C21.6280356,22.6253182 23.138826,22.6253182 24.2643299,22.5217391 L24.2643299,18.7417391 L27.4141385,18.8435681 L41.8556701,3.65217391 L33.7296907,3.65217391 L15.7317526,22.0556208 Z"/>
                    <path fill="#D2CCC8"
                          d="M42.2704127,1.04354775 C51.6121021,2.61040269 58,5.77989531 58,9.43478261 C58,14.6454692 45.0162577,18.8695652 29,18.8695652 C27.3873138,18.8695652 25.8053731,18.8267388 24.2643366,18.7443911 Z M29,0 C30.6131407,0 32.1955187,0.0428505303 33.7369665,0.125243754 L15.7292424,17.8259596 C6.38774068,16.2590845 0,13.0896249 0,9.43478261 C0,4.22409606 12.9837423,0 29,0 Z"/>
                    <polygon fill="#BCB4B1" points="33.73 .128 33.73 3.652 15.732 22.056 15.732 17.817"/>
                </g>
                <g transform="translate(321 270)">
                    <path fill="#EAE8E5"
                          d="M58,9.13043478 L58,18.8695652 L57.9851852,18.8696886 C57.4911432,23.9394623 44.7031194,28 29,28 C13.2968806,28 0.508856801,23.9394623 0.0148148381,18.8696886 L0,18.8695652 L0,9.13043478 L58,9.13043478 Z"/>
                    <path fill="#E4E0DE"
                          d="M15.7317526,22.0556208 C16.5024799,22.2627873 17.8358819,22.4181601 19.7319588,22.5217391 C21.6280356,22.6253182 23.138826,22.6253182 24.2643299,22.5217391 L24.2643299,18.7417391 L27.4141385,18.8435681 L41.8556701,3.65217391 L33.7296907,3.65217391 L15.7317526,22.0556208 Z"/>
                    <path fill="#D2CCC8"
                          d="M42.2704127,1.04354775 C51.6121021,2.61040269 58,5.77989531 58,9.43478261 C58,14.6454692 45.0162577,18.8695652 29,18.8695652 C27.3873138,18.8695652 25.8053731,18.8267388 24.2643366,18.7443911 Z M29,0 C30.6131407,0 32.1955187,0.0428505303 33.7369665,0.125243754 L15.7292424,17.8259596 C6.38774068,16.2590845 0,13.0896249 0,9.43478261 C0,4.22409606 12.9837423,0 29,0 Z"/>
                    <polygon fill="#BCB4B1" points="33.73 .128 33.73 3.652 15.732 22.056 15.732 17.817"/>
                </g>
            </g>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>