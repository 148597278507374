<template>
    <img
        class="crv-image"
        :class="classes"
        :style="styles"
        :src="imageUrl"
        :alt="imageAlternateText"
    >
</template>

<script>
export default {
    name: 'CrvImage',

    props: {
        imageUrl: {
            type: String,
            required: true,
            note: 'Sets the background-image inside the div'
        },

        imageAlternateText: {
            type: String,
            required: true,
            note: 'Sets the alternate text of the image.'
        },

        shape: {
            type: String,
            default: 'circle',
            note: 'forces the image into a certain shape; \'circle\': Scales the image into a circle of the set size.'
        },

        size: {
            type: Number,
            default: 68,
            note: 'sets the image-size in pixels in width and height. Default is "68px"'
        }
    },

    computed: {
        classes() {
            return [
                { 'is-circular': this.shape === 'circle' },
            ]
        },

        styles() {
            return [
                { width: `${this.size}px`, height: `${this.size}px` },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.crv-image {
    &.is-circular {
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0);
    }
}
</style>
