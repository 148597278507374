const TOKEN = 'token'
const REFRESH_TOKEN = 'refreshToken'
const ID_TOKEN = 'idToken'
const USER_NAME = 'userName'
const CUSTOMER_NUMBER = 'customerNumber'
const AUTHORIZED_CUSTOMERS = 'authorizedCustomers'
const CUSTOMER_NAME = 'customerName'
const LOCATION_NUMBER = 'locationNumber'

function saveToken(token, refreshToken, idToken) {
    localStorage.setItem(TOKEN, token)
    localStorage.setItem(REFRESH_TOKEN, refreshToken)
    localStorage.setItem(ID_TOKEN, idToken)
}

function loadToken() {
    const token = localStorage.getItem(TOKEN)
    const refreshToken = localStorage.getItem(REFRESH_TOKEN)
    const idToken = localStorage.getItem(ID_TOKEN)
    return (token && refreshToken && idToken)
        ? {
            [TOKEN]: token,
            [REFRESH_TOKEN]: refreshToken,
            [ID_TOKEN]: idToken,
        }
        : undefined
}

function deleteToken() {
    localStorage.removeItem(TOKEN)
    localStorage.removeItem(REFRESH_TOKEN)
    localStorage.removeItem(ID_TOKEN)
}

function loadUserName() {
    return localStorage.getItem(USER_NAME)
}

function saveUserName(userName) {
    localStorage.setItem(USER_NAME, userName)
}

function loadCustomerNumber() {
    return localStorage.getItem(CUSTOMER_NUMBER)
}

function saveCustomerNumber(customerNumber) {
    if (customerNumber) {
        localStorage.setItem(CUSTOMER_NUMBER, customerNumber)
    } else {
        localStorage.removeItem(CUSTOMER_NUMBER)
    }
}

function loadAuthorizedCustomers() {
    return localStorage.getItem(AUTHORIZED_CUSTOMERS)
}

function loadCustomerName() {
    return localStorage.getItem(CUSTOMER_NAME)
}

function loadLocationNumber() {
    return localStorage.getItem(LOCATION_NUMBER)
}

export default {
    saveToken,
    loadToken,
    deleteToken,
    loadUserName,
    saveUserName,
    loadCustomerNumber,
    saveCustomerNumber,
    loadAuthorizedCustomers,
    loadCustomerName,
    loadLocationNumber,
}
