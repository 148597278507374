<template>
    <!-- eslint-disable -->
<svg class="dashboard-card-mobile crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 712 585">
    <defs>
        <polygon id="dashboard-card-b" points="91 26.295 422 0 422 247.708 91 221.413"/>
        <filter id="dashboard-card-a" width="114.5%" height="119.4%" x="-7.3%" y="-9.7%"
                filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8"/>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
            <feColorMatrix in="shadowBlurOuter1"
                           values="0 0 0 0 0.149019608   0 0 0 0 0.137254902   0 0 0 0 0.133333333  0 0 0 0.0987762238 0"/>
        </filter>
        <linearGradient id="dashboard-card-c" x1="50%" x2="50%" y1="100%" y2="0%">
            <stop offset="0%" stop-color="#4E88AF" stop-opacity="0"/>
            <stop offset="100%" stop-color="#265077" stop-opacity=".287"/>
        </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(0 98.146)">
        <ellipse cx="256" cy="236.854" fill="#F7F6F6" rx="256" ry="50"/>
        <g fill-rule="nonzero">
            <use fill="#000" filter="url(#dashboard-card-a)" xlink:href="#dashboard-card-b"/>
            <path fill="#FFF" fill-rule="evenodd" stroke="#F7F6F6" stroke-linejoin="inherit"
                  d="M91.5,26.7568662 L91.5,220.951148 L421.5,247.166718 L421.5,0.541295803 L91.5,26.7568662 Z"/>
        </g>
        <path fill="#D2CCC8"
              d="M228,81.994944 C231.313708,81.8359304 234,83.9566076 234,86.7316091 L234,90.0813323 C234,92.8563339 231.313708,95.2154779 228,95.3506281 L119,99.7962104 C115.686292,99.9313605 113,97.9376361 113,95.3431023 L113,92.2112229 C113,89.6166891 115.686292,87.3844978 119,87.2254842 L228,81.994944 Z M152,40.8126171 C155.313708,40.5700813 158,42.5311607 158,45.1928106 L158,74.1088717 C158,76.7705216 155.313708,79.0667938 152,79.2377391 L119,80.9401211 C115.686292,81.1110665 113,79.1463598 113,76.551826 L113,48.3649116 C113,45.7703778 115.686292,43.4704784 119,43.2279425 L152,40.8126171 Z"/>
        <path fill="#E4E0DE"
              d="M118,203.870748 L197,210.289032 C199.761424,210.513381 202,212.933829 202,215.695252 C202,218.456676 199.761424,220.513381 197,220.289032 L118,213.870748 C115.238576,213.646399 113,211.225952 113,208.464528 C113,205.703104 115.238576,203.646399 118,203.870748 Z"/>
        <path fill="#EAE8E5"
              d="M169,53.6472653 L179,53.3026959 C182.313708,53.1885157 185,55.7822458 185,59.0959543 L185,71.0959543 C185,74.4096628 182.313708,77.1885157 179,77.3026959 L169,77.6472653 C165.686292,77.7614456 163,75.1677154 163,71.8540069 L163,59.8540069 C163,56.5402984 165.686292,53.7614456 169,53.6472653 Z"/>
        <path fill="url(#dashboard-card-c)"
              d="M112,156.854007 C147.333333,142.464192 178.333333,136.324148 203,139.280019 C240,144.104211 255,177.272856 304,173.722027 C336.666667,171.010145 367.666667,145.962506 397,96.5204166 L397,214.540608 L112,192.854007 L112,156.854007 Z"/>
        <path stroke="#75A3CD" stroke-linecap="round" stroke-width="14"
              d="M119,156.854007 C150.333333,140.931988 178.333333,134.417998 203,137.895181 C240,143.316364 255,178.272957 304,173.888556 C336.666667,170.784268 365.333333,145.735456 390,97.84853"/>
        <path fill="#D2CCC8"
              d="M379.535534,27.2053781 L393.67767,40.0615196 C395.630291,41.8227991 395.630291,44.982195 393.67767,47.1242833 L379.535534,62.7384866 C377.582912,64.9081415 374.417088,65.244104 372.464466,63.4828245 C370.511845,61.7182007 370.511845,58.5239287 372.464466,56.3542738 L383.071,44.6270451 L372.464466,34.9686217 C370.511845,33.1797758 370.511845,29.9855038 372.464466,27.840071 C374.417088,25.6979827 377.582912,25.4165321 379.535534,27.2053781 Z"/>
    </g>
</svg>
<!-- eslint-enable -->
</template>